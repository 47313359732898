<template>
  <div
    class="wrapper mx-auto d-flex flex-column align-center"
    style="gap: 8px"
  >
    <gallery-crypko-section
      :crypkos="crypkos && crypkos.hibiscus"
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.HIBISCUS}`)"
      :to="{ name: GALLERY_ROUTE_NAME.HIBISCUS}"
      icon="mdi-account"
    />
    <gallery-crypko-section
      :crypkos="crypkos && crypkos.crossfuse"
      :to="{ name: GALLERY_ROUTE_NAME.CROSSFUSE }"
      icon="mdi-vector-combine"
    >
      <template #title>
        <span>
          {{ $t(`gallery.views.${GALLERY_VIEW_NAME.CROSSFUSE}`) }}
        </span>
        <v-btn
          class="mt-n1"
          icon
          small
          :to="{
            path: '/help#crossfuse',
          }"
        >
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
      </template>
    </gallery-crypko-section>
    <gallery-crypko-section
      :crypkos="crypkos && crypkos.following"
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.FOLLOWING}`)"
      :to="{ name: GALLERY_ROUTE_NAME.FOLLOWING }"
      icon="mdi-account-multiple"
    />
    <gallery-crypko-section
      :crypkos="crypkos && crypkos.chattable"
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.CHATTABLE}`)"
      :to="{ name: GALLERY_ROUTE_NAME.CHATTABLE }"
      icon="mdi-chat"
    />
    <gallery-crypko-section
      :crypkos="crypkos && crypkos.gerbera"
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.GERBERA}`)"
      :to="{ name: GALLERY_ROUTE_NAME.GERBERA }"
      icon="mdi-human-handsdown"
    />
    <gallery-crypko-section
      :crypkos="crypkos && crypkos.freesia"
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.FREESIA}`)"
      :to="{ name: GALLERY_ROUTE_NAME.FREESIA }"
      icon="mdi-account"
    />
    <gallery-crypko-section
      :crypkos="crypkos && crypkos.erica"
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.ERICA}`)"
      :to="{ name: GALLERY_ROUTE_NAME.ERICA }"
      icon="mdi-face-man"
    />
  </div>
</template>

<script>
import GalleryCrypkoSection from './GalleryCrypkoSection.vue'
import Crypko from '@/utils/crypko'
import { GALLERY_ROUTE_NAME, GALLERY_VIEW_NAME } from '@/utils/constants'

export default {
  name: 'gallery-home',
  components: {
    GalleryCrypkoSection,
  },
  data () {
    return {
      GALLERY_ROUTE_NAME,
      GALLERY_VIEW_NAME,
      crypkos: null,
    }
  },
  async created () {
    this.crypkos = await Crypko.getGalleryCrypko()
  },
}
</script>
