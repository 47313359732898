<template>
  <v-badge
    :value="badge && (unclaimedCount || unread)"
    color="primary"
    overlap
    dot
    offset-x="10"
    offset-y="10"
  >
    <v-menu
      v-if="user"
      left
      offset-y
      transition="slide-y-transition"
      :disabled="disableMenu"
    >
      <template #activator="{ on }">
        <div
          :class="{'avatar-image': !disableMenu}"
          v-on="on"
        >
          <v-avatar
            :tile="false"
            :size="size"
            class="white"
          >
            <v-img
              :src="avatarSrc"
              :lazy-src="DEFAULT_AVATAR_SRC"
              alt="avatar"
              @error="() => srcFailed = true"
            />
          </v-avatar>
          <v-icon
            v-if="!disableMenu"
            class="mx-n1"
            color="grey"
          >
            mdi-menu-down
          </v-icon>
        </div>
      </template>
      <div>
        <v-list class="avatar-menu-list">
          <div
            class="mb-3 mt-1 d-flex justify-center"
          >
            <router-link
              :to="{ name: 'plans', hash: '#exchange' }"
            >
              <coin-widget
                v-if="user"
                :user="user"
              />
            </router-link>
          </div>
          <v-divider class="mx-3" />
          <!-- :href for outside url and :to for inner router -->
          <v-list-item
            :to="{
              name: 'user-home',
              params: { id: user.id },
            }"
          >
            <v-list-item-icon>
              <user-avatar-icon icon="mdi-home" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(`header.avatar.home`) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'notifications' }">
            <v-list-item-icon>
              <user-avatar-icon
                icon="mdi-bell"
                :badge-value="unread"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('header.avatar.notifications') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :to="{
              name: 'plans',
            }"
          >
            <v-list-item-icon>
              <user-avatar-icon
                color="secondary" 
                icon="mdi-store"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('header.avatar.plans') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="onDailyTaskClick()">
            <v-list-item-icon>
              <user-avatar-icon
                color="secondary"
                :badge-value="unclaimedCount || (unfinishedExists ? 1 : 0)"
                :badge-dot="unfinishedExists && unclaimedCount === 0"
                icon="mdi-checkbox-multiple-marked"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('header.avatar.daily-task') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="onStampCardClick()">
            <v-list-item-icon>
              <user-avatar-icon icon="mdi-calendar" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('header.avatar.stampCard') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="onSystemNotifictionClick()">
            <v-list-item-icon>
              <user-avatar-icon icon="mdi-email-newsletter" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('header.avatar.system-notification') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'settings' }">
            <v-list-item-icon>
              <user-avatar-icon icon="mdi-cog" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('header.avatar.settings') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'help' }">
            <v-list-item-icon>
              <user-avatar-icon icon="mdi-help-circle" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('header.avatar.help') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="primary--text"
            @click="onLogoutClick"
          >
            <v-list-item-icon>
              <user-avatar-icon
                color="primary"
                icon="mdi-logout"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('header.avatar.logout') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-menu>
  </v-badge>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import User from '@/utils/user'
import store from '@/store'
import router from '@/router'
import OSSUtils from '@/utils/oss'
import CoinWidget from '@/widgets/CoinWidget.vue'

import { DEFAULT_AVATAR_SRC } from '@/utils/constants'
import { mapFields } from 'vuex-map-fields'
import UserAvatarIcon from './UserAvatarIcon.vue'

export default {
  name: 'user-avatar',
  components: {
    CoinWidget,
    UserAvatarIcon,
  },
  props: {
    user: {
      type: Object,
      default: () => store.state.user.user,
    },
    disableMenu: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '40px',
    },
    badge: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      DEFAULT_AVATAR_SRC,
      srcFailed: false,
    }
  },
  computed: {
    ...mapGetters('task', ['unclaimedCount']),
    ...mapFields(['stampCardDialog', 'changelogDialog', 'dailyTaskDialog']),
    ...mapState('user', ['homeAvatarSrc']),
    ...mapState('notification', ['notificationsData', 'unread']),
    unclaimedCount () {
      if (!this.user?.tasks) return 0
      return this.user.tasks.filter(task => task.finished && !task.reward_claimed).length
    },
    unfinishedExists () {
      if (!this.user?.tasks) return false
      return this.user.tasks.filter(task => !task.finished).length > 0
    },
  },
  asyncComputed: {
    async avatarSrc () {
      if (!this.user || this.srcFailed) {
        return DEFAULT_AVATAR_SRC
      } else if (this.user.id == store.state.user.user.id) {
        return this.homeAvatarSrc
      } else {
        return await OSSUtils.getUserAvatar(this.user.id)
      }
    },
  },
  watch: {
    user () {
      this.srcFailed = false
    },
  },
  created () {
    this.$store.dispatch('user/updateHomeAvatarSrc')
  },
  methods: {
    onSystemNotifictionClick () {
      this.changelogDialog = true
    },
    onDailyTaskClick () {
      this.dailyTaskDialog = true
    },
    onStampCardClick () {
      this.stampCardDialog = true
    },
    async onLogoutClick () {
      await User.logout()
      router.push({ name: 'signup-signin' })
    },
  },
}
</script>

<style scoped lang="scss">
.avatar-image {
  cursor: pointer;
}
.v-list-item__icon {
  margin-right: 1rem !important;
}
</style>
