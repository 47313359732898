export default {
  general: {
    crypko: 'Crypko',
    model: 'モデル',
    slot: 'スロット',
    stamina: 'CP',
    coin: 'スターコイン',
    guideline: 'ガイドライン',
    terms: '利用規約',
    'privacy-policy': 'プライバシーポリシー',
    'crypko-guideline': 'Crypkoガイドライン',
    'commercial-license': '商用ライセンス',
    dev: 'dev',
    support: {
      email: 'support@info.crypko.ai',
      'faq-link': 'https://crypko.zendesk.com/hc/ja/categories/10851919592601-FAQ',
      'contact-link': 'https://crypko.zendesk.com/hc/ja/requests/new',
    },
    label: {
      generate: '生成',
      fuse: '融合',
      settings: '設定',
      set: '設定',
      functionality: '機能',
      save: '保存',
      delete: '削除',
      update: '変更',
      apply: '適用',
      select: '選択',
      pleaseSelect: '選択してください',
      edit: '編集',
      yes: 'はい',
      no: 'いいえ',
      reset: 'リセット',
      close: '閉じる',
      submit: '提出',
      download: 'ダウンロード',
      cancel: 'キャンセル',
      confirm: '確認',
      share: 'シェアする',
      report: '問題を報告',
      follow: 'フォロー',
      following: 'フォロー中',
      all: 'すべて',
      error: 'エラー',
      language: '言語',
      load: 'ロード',
      done: '完了',
      more: 'もっと見る',
      refresh: '再読み込み',
      none: 'なし',
      purchase: '購入',
      retry: 'やり直す',
      full: 'フル',
      agree: '同意する',
      send: '送信',
      resend: '再送信',
      'delete-unwanted': 'お持ちのCrypkoを整理する',
      account: 'アカウント',
      comment: 'コメント',
      'slots-remain': '残りスロット',
      'contact-by-email': 'メールでお問い合わせ',
      'email-address': 'メールアドレス',
      times: '回数',
      fav: 'いいね',
      notifications: '通知',
      upgrade: 'アップグレード',
      support: 'サポート',
      result: '結果',
      owner: 'オーナー',
      'try-now': '今すぐ試す',
      'acquire-now': '入手しよう',
      'view-other-crypkos': '他のCrykpoを見る',
      'try': 'やってみる',
      'claim': '受け取る',
      'claim-all': '一括受け取る',
      'claimed': '受取済み',
      exchange: '交換',
      details: '詳細',
      reward: '報酬',
      feedback: 'フィードバック',
      'continue-anyway': 'それでも続ける',
      characters: 'キャラクター',
      recommended: 'おすすめ',
      others: 'その他',
      'go-gen': '生成へ',
      feature: '機能',
    },
    settings: {
      AttrsCPConfirm: '属性機能のCP確認',
      NSFWConfirm: 'センシティブ画像の表示',
    },
    msg: {
      succeeded: '成功しました。',
      failed: '失敗しました。',
      retry: 'もう一度試してください。',
      saveConfirm: '保存しますか？',
      saveSeveralConfirm: '{number}件を保存しますか？',
      saved: '保存しました',
      unsaved: '前回の編集が残っています',
      overwrite: '上書きしますか？',
      delete: '削除しますか？',
      fetching: '読み込み中です',
      placeholder: '入力してください',
      empty: 'まだありません。',
      error: 'エラーが発生しました。',
      enableAfterSubscribe: '有料プランでご利用出来ます。',
      thankyou: 'ありがとうございます！',
      waiting: 'お待ちください。',
      welcome: 'Crypkoへようこそ！',
      'cp-insufficient': 'CP不足',
      'coin-insufficient': 'スターコイン不足',
      'before-share': 'SNSにシェアされる際には、 {0}の遵守にご協力ください。',
      'cancel-crossfuse': 'コラボ融合を取り消しますか？',
      'own-crypko': '自分が所持しているCrypkoです。',
      'delete-licensed-crypko': 'ライセンス購入済みのCrypkoを削除すると、商用利用が出来なくなります。',
      'max-length': '最大文字数：{0}。',
      'refresh-in': 'リフレッシュまで {0}',
      'crypko-deleted': 'Crypkoが削除されました',
    },
  },
  models: {
    erica: 'Erica',
    freesia: 'Freesia',
    'freesia-m': 'FreesiaM',
    gerbera: 'Gerbera',
    'gerbera-m': 'GerberaM',
    hibiscus: 'Hibiscus',
    stylegan: 'StyleGAN',
    'edit-v1': 'EditV1',
    'canvas-v1': 'CanvasV1',
    others: 'その他',
    desc: {
      'hibiscus': '上半身',
      'gerbera': '全身',
      'gerbera-m': '全身',
      'freesia': '上半身',
      'freesia-m': '上半身',
      'erica': '顔',
      'canvas-v1': '顔',
    },
    'supported-models': '対応モデル',
    'supported-feature': '対応機能',
    'gen-sample': '生成例',
    free: '無料モデル',
    paid: '有料モデル',
  },
  crypko: {
    card: {
      tooltip: {
        chat: 'チャット',
        crossfuse: 'コラボ融合',
        nsfw: 'NSFW',
      },
    },
    profile: {
      set_with_error: '承認失敗の文言含む可能性あります',
      save: 'プロフィール保存',
      generate: 'プロフィール生成',
      generate_title: 'プロフィール',
      save_confirm_desc: 'チャットでプロフィールを使いますか？',
      save_and_chattable: 'チャット可能にする',
      save_and_unchattable: 'チャット不可にする',
      start_chat_title: '保存しました',
      start_chat_desc: '今すぐチャット可能です',
      start_chat: 'チャット',
      empty: 'プロフィールがありません',
      edit: 'クリックするとプロフィールを編集できます',
      choose_profile_title: 'プロフィールを選択',
      choose_profile_desc: 'このCrypkoの作成者は、プロフィールを更新しました。新しいプロフィールを使いますか？',
      use_new: '新しいプロフィールを使う',
      use_old: '古いプロフィールを使う',
      generate_and_chat_title: 'プロフィールを作る',
      generate_and_chat_desc: 'プロフィールを作成してチャットを始めましょう',
      input: 'プロフィールを入力する',
    },
    type: {
      GEN: '生成',
      FUSED: '融合',
      ATTRS: '属性',
      CANVAS: 'キャンバス',
      VAR: '派生',
      KEYPOINT: 'ポーズ',
      PROJ: 'Crypko化',
    },
    'relation-map': 'リレーションマップ',
    crossfuse: 'コラボ融合',
    latest: '新着',
    owned: '所持',
  },
  auth: {
    'signup-signin': '登録・ログイン',
    signup: 'アカウント作成',
    signin: 'ログイン',
    signout: 'ログアウト',
    username: 'ユーザー名',
    password: 'パスワード',
    'reset-pwd': 'パスワードリセット',
    'change-email': 'メールアドレス変更',
    'input-new-email': '変更',
    'new-email-dialog-title': 'メールアドレス変更',
    'new-email-dialog-desc': '新しいメールアドレスを入力してください',
    'new-email-label': '新しいメールアドレス',
    'verify-pwd': 'パスワードを確認',
    'verify-pwd-desc': 'パスワードを確認してください。',
    'email-sent-title': 'メールを送信しました',
    'email-sent-desc': '確認メールを確認し、メールアドレス変更作業を完了させてきてください。',
    'confirm': '確認',
    'send-email': 'メールを送信する',
    'send-reset-email': 'メールでリセット',
    'new-pwd': '新しいパスワード',
    'confirm-pwd': 'パスワード(確認)',
    'forget-pwd': 'パスワードを忘れた場合',
    'pwd-mismatch': 'パスワードが間違っています。',
    'invalid-email': 'Emailが間違っています。',
    'invalid-login': 'メールアドレスまたはパスワードが違います。メールアドレス、パスワードは大文字と小文字を区別する必要があります。',
    'email-sent': 'メールを送信しました。再送信する場合は%{second}秒後に試してください。',
    goCreate: 'キャラクターを作ろう',
    goGallery: 'ギャラリーを見る',
    goPlayground: 'チャットしてみよう',
    goTravel: 'トラベルしてみよう',
    'email-verify-failed': 'メール認証が失敗しました。',
    'please-login': 'ログインしてください。',
    'official-twitter': '公式ツイッター',
    'official-discord': '公式Discord',
    'age-confirm': '{0}に定めるアカウント作成が可能な年齢です。',
    'age-confirm-terms': '利用規約第3条',
    'agreements': '{0}と{1}に同意します。',
    'agree-on-click': '「アカウントを作成」をクリックすることにより、以下に同意したものとみなされます。',
    'agree-on-guideline': '{0}を読み、これに承諾します。',
    'delete-account': 'アカウント削除',
    'delete-account-confirm': 'はい、アカウントを削除する',
    'delete-account-title': 'アカウントを削除しますか？',
    'delete-account-msg': 'アカウントを削除すると、ライブラリに登録されたCrypko、CP、購入されたスロットがすべて削除されます。なお、一度削除されたアカウントの復旧はできません。',
    'delete-account-subscription-title': 'サブスクリプション警告',
    'delete-account-subscription-msg': 'アカウントを削除するためにはサブスクリプションを解約する必要があります。',
    'continue-with-google': 'Googleで続ける',
    'or': 'または',
    verifyEmail: {
      'title': 'メールアドレスを認証',
      'desc': '当サイトのご利用にはメールアドレスの認証が必要です。メール受信箱を確認して説明に従ってください。',
      'done-browse': 'アカウントが認証されました。',
      'done-login': 'アカウントが認証されました。ログインしてください。',
      'email-sent-address': '送信先: %{address}',
    },
  },
  dialog: {
    noSegmentation: {
      title: 'Load Error',
      text: '読み込み失敗しました。<br/>画面をリロードしてもう一度試してください。',
      actions: {
        true: 'OK',
      },
    },
    nsfw: {
      text: 'この画像はセンシティブな内容を含んでいる可能性があります。',
      actions: {
        true: '表示する',
        false: '表示しない',
      },
    },
    'confirm-terms': {
      title: '利用規約改定のお知らせ',
      text: '{0}年{1}月{2}日付で、本サービスの利用規約を改定いたします。本改定により、本サービスには以下改定後の利用規約が適用されます。',
      actions: {
        true: '同意する',
        false: '同意しない',
      },
    },
    'freemium-fuse': {
      text: '所持のCrypko同士を融合するためには、有料プランが必要です。',
    },
    'chat-cp': {
      text: '送信する度に３CPを消費します。\n※この確認メッセージは一度しか表示しません。',
    },
    'chat-freemium': {
      title: 'チャット',
      actions: {
        0: 'イメージキャラで試す',
      },
    },
    'get-coin': '{delta}スター！合計：{total}。',
    'spend-coin': '{delta}スター！残り：{total}。',
    'coin-exchanged': {
      title: '交換不可',
    },
    'not-enough-coin': {
      title: 'スター不足',
      message: 'スターが足りません。デイリータスクをクリアしてスターを獲得しましょう。',
    },
    'remove-album': {
      title: '確認',
      message: 'このトラベル写真をCrypkoから削除しますか？ ',
      message2: 'このトラベル写真をCrypkoから削除しますか？ この操作は取り消しできない場合があります。',
    },
    'earn-coins': {
      title: 'コインのもらい方',
      'daily-task': 'デイリータスクをこなそう。',
      travel: 'トラベルでもらおう。',
    },
    'require-crypko': {
      title: 'Crypkoが必要です',
      message: '生成機能でCrypkoを生成しますか？',
    },
    'generate-crypko': {
      title: 'Crypko生成',
      'save-and-continue': '保存して続ける',
      'msg-travel': 'トラベルを始めるには、Hibiscus/Freesia/GerberaモデルのCrypkoを所有する必要があります。',
    },
    'survey-no-travel': {
      'title': '500コインを無料でゲット',
      'message': 'アンケートに回答して500コインを獲得しましょう。Crypkoトラベルを少なくとも1回プレイする必要があります。',
      'action': 'Crypkoトラベル',
    },
  },
  header: {
    router: {
      page404: '404',
      about: 'About',
      'create-attributes': '属性',
      'create': 'Create',
      'create-canvas': 'キャンバス',
      emoteDemo: 'E-mote',
      'create-fuse': '融合',
      'create-generate': '生成',
      'create-keypoint': 'ポーズ',
      'create-marking': 'マーキング',
      'create-variation': '派生',
      'create-interpolate': '変身動画',
      'workbench': 'エディター',
      'user-home': 'ホーム',
      'user-crypko': 'Crypkos',
      'user-album': 'トラベル',
      'user-faved': 'お気に入り',
      'notifications': '通知',
      'user-list': 'リスト',
      'followings-user': 'フォロー中',
      'followers-user': 'フォロワー',
      signin: 'ログイン',
      list: 'リスト',
      logout: 'ログアウト',
      gallery: 'ギャラリー',
      'signup-signin': '登録・ログイン',
      'signup': 'アカウント作成',
      'signup-welcome': 'アカウント作成',
      'introduction': 'Crypko - AIアニメキャラクター生成',
      help: 'ヘルプ',
      settings: '設定',
      'terms': '利用規約',
      'privacy-policy': 'プライバシーポリシー',
      'guideline': 'ガイドライン',
      'verify-email': 'メール認証',
      'verify-password': 'パスワード認証',
      'reset-pwd-request': 'パスワードリセット',
      'reset-pwd-submit': 'パスワードリセット',
      plans: 'プラン',
      'gallery-crypko': 'ギャラリー',
      'gallery-trend': 'トレンド',
      'gallery-chattable': 'チャット',
      'gallery-crossfuse': 'コラボ融合',
      'gallery-following': 'フォロー中',
      'gallery-gerbera': 'Gerbera',
      'gallery-freesia': 'Freesia',
      'gallery-freesia_m': 'FreesiaM',
      'gallery-erica': 'Erica',
      'gallery-search': 'Search',
      'gallery-travel': 'ギャラリー',
      'crypko-tree': 'リレーションマップ',
      travel: 'トラベル',
      play: 'Play',
      survey: 'アンケート',
    },
    avatar: {
      home: 'ホーム',
      notifications: '通知',
      stampCard: 'スタンプカード',
      plans: 'ストア',
      settings: '設定',
      help: 'ヘルプ',
      logout: 'ログアウト',
      'system-notification': 'お知らせ',
      'daily-task': 'デイリータスク',
    },
  },
  gallery: {
    views: {
      home: 'ホーム',
      album: 'トラベル',
      crypkos: 'Crypko',
      chattable: 'チャット',
      lists: 'リスト',
      users: 'ユーザー',
      trend: 'トレンド',
      crossfuse: 'コラボ融合',
      following: 'フォロー中',
      hibiscus: 'Hibiscuas新着',
      gerbera: 'Gerbera(M)新着',
      freesia: 'Freesia(M)新着',
      erica: 'Erica新着',
      canvas: 'Canvas新着',
      rank: 'ランキング',
    },
    filter: {
      label: 'フィルター',
      options: 'オプション',
      model: 'モデル',
      type: 'タイプ',
      status: 'ステータス',
    },
    ordering: {
      'title': '並べ替え',
      'created': '古い順',
      '-created': '新しい順',
      'name': '名前順(A→Z)',
      '-name': '名前順(Z→A)',
      '-likes': 'いいね順',
      'default': 'おすすめ',
      'date_joined': '古い順',
      '-date_joined': '新しい順',
      'username': '名前順(A→Z)',
      '-username': '名前順(Z→A)',
      'added': '追加古い順',
      '-added': '追加新しい順',
      'likes': 'いいね順',
      'claimed_at': '新しい順',
    },
  },
  user: {
    status: {
      'please-login': '登録・ログインしてください',
      empty: 'まだありません',
    },
  },
  privacy: {
    setting: '公開設定',
    public: {
      title: '公開',
      description: '他のユーザーが閲覧できます。',
      save: '公開保存',
    },
    private: {
      title: '非公開',
      description: '自分にしか見えず、シェアできません。',
      save: '非公開保存',
    },
  },
  avatar: {
    avatar: 'アバター',
    select: 'アバター設定',
  },
  stamp: {
    loginBonus: 'ログインボーナス',
    'next-stamp-in': '次のスタンプまで {0}',
    preparing: '準備中です。',
    obtain: '毎日ログインでもらう',
  },
  detail: {
    profile: {
      title: 'プロフィール',
      default: 'Crypko {name} のプロフィール',
    },
    hash: {
      title: 'ハッシュ',
    },
    created: {
      title: '作成日時',
    },
    model: {
      title: 'モデル',
      tooltip: 'The model that generated the Crypko',
    },
    type: {
      title: 'タイプ',
      tooltip: 'The type of the Crypko',
    },
    rating: {
      title: 'レーティング',
      empty: 'なし',
    },
    comment: {
      title: 'コメント',
      edit: '編集',
      empty: '',
    },
    parentCrypko: {
      title: '元のCrypko',
      empty: 'なし',
    },
    childCrypko: {
      title: '派生のCrypko',
      empty: 'なし',
    },
    tags: {
      title: 'タグ',
      empty: 'タグが付いていません',
      rate: '出現率',
    },
    name: {
      edit: '名前を編集する',
    },
    toolbar: {
      'favorite': 'お気に入り',
      'share': 'シェア',
      'addList': 'リストに追加',
      'edit': '編集',
      'download': 'ダウンロード',
    },
  },
  edit: {
    composition: '構図',
    layer: 'レイヤー',
    style: 'スタイル',
    layers: {
      'background': '背景',
      'backHair': '後髪',
      'skin': '肌',
      'face': '顔',
      'frontHair': '前髪',
      'clothes': '服',
      'eyeWhite': '白目',
      'eyeBlack': '黒目',
      'eyelashes': 'まつげ',
      'nose': '鼻',
      'mouth': '口',
      'eyebrows': '眉毛',
    },
    settings: {
      mode: {
        drawing: 'ブラシ',
        eraser: '消しゴム',
        selection: '選択',
        rectangleCutout: '矩形切り取り',
        freeCutout: 'フリー切り取り',
        colorBucket: 'カラーバケツ',
      },
      cursorSize: 'カーソルサイズ',
      mixRatio: 'ミックス',
    },
  },
  footer: {
    'contact': {
      'title': 'お問い合わせ',
      'text-1': 'Crypko の機能についてのご不明点は{0}ページをご参照ください。',
      'text-2': 'その他のお問い合わせは{0}までにお願いいたします。',
      'text-3': '※ただいまCrypko開発チームは鋭意開発中のため、お問い合わせにご返信できるまで時間がかかる場合がございます。ご期待に添えず申し訳ございませんが、予めご了承いただけますと幸いです。',
      'text-4': '※公式{0}コミュニティでご質問いただけますと、より早く回答がもらえる可能性がありますので、ぜひご活用ください。',
    },
  },
  help: {
    usage: '使い方',
    create: {
      generate: {
        desc: 'ワンクリックでキャラクターを生成する',
        1: '1. 生成モデルを選択して生成する。',
        2: '2. 好きな生成結果を選択し（複数選択可）、保存する。気に入っている結果がない場合はもう一度生成する。',
      },
      fuse: {
        desc: '同じモデルのキャラクター2つを融合して、新しいキャラクターを生成する',
        1: '1. 融合させたい同じモデルのCrypkoを二つ選択して生成する。',
        0: '※自分のCrypko同士を融合するためには有料プランが必要です。',
        2: '2. 好きな生成結果を選択し（複数選択可）、保存する。気に入っている結果がない場合はもう一度生成する。',
      },
      crossfuse: {
        desc: 'コラボ融合は他のユーザーのCrypkoと自分のCrypkoを融合できる機能。コラボ融合ごとに、融合する側が30CPを消費する一方で、提供する側が10CPをもらえる（一日一回限り）。',
        2: '設定方法',
        3: 'ユーザーホーム画面で自分のコラボ融合Crypkoを設定できる。',
        4: '使い方',
        5: '融合選択ダイアログでコラボ融合できるCrypkoを選択する。',
        6: '自分のCrypkoを選択して融合する。',
        7: 'Crypko画面のコラボ融合ボタンでもコラボ融合に入れる。',
      },
      attributes: {
        desc: 'キャラクターの属性を調整する',
        1: '1. 属性を調整したいCrypkoを選択する。',
        2: '2. 好きな属性の数値を調整して（複数属性可）、変更する。',
        3: '3. 結果が気に入ったら保存する。',
      },
      variation: {
        desc: '違うポーズや服などのキャラクターバリエーションを生成する',
        1: '1. 派生させたいCrypkoを選択する。',
        2: '2. 派生モードと強度を決める。',
        3: '3. 派生ボタンをクリックする。',
        4: '4. 結果が気に入ったら保存する。',
      },
      canvas: {
        desc: 'キャラクターをキャンバスで自由に編集する',
        0: '※編集の適用はCPを消費しません。',
        '01': '※保存は20CPを消費します.',
        '02': '※上記の仕様は、キャンバス機能βテストの終了時に変更される可能性があります。予めご了承ください。',
        1: '1. ベースの構図となるCrypko（EricaとCanvasV1モデルのみ）を選択する。',
        2: '2. ツールを使ってキャンバスを編集する。',
        3: '3. (オプショナル) スタイル参照画像が別のCrypkoに指定できます。',
        4: '4. （オプショナル）レイヤーにマウス右クリックすると構図画像とスタイル参照画像のミックス比率を調整できる。',
        5: '5. 「適用」ボタンで編集を適用し、気に入ったら保存する。',
      },
      keypoint: {
        0: '*まだ開発中です。',
        1: '1. ポーズを変更したいCrypkoを選択する。',
        2: '2. 参考ポーズのCrypkoを選択する。',
        3: '3. 変更ボタンをクリックする。',
        4: '4. 結果が気に入ったら保存する。',
      },
      interpolate: {
        desc: '2つのCrypkoの変身動画を生成する',
        0: '※変身動画で作成した動画は商用利用できません。',
        1: '※動画の生成には時間がかかる場合があります。',
        2: '※上記の仕様は、変身動画機能βテストの終了時に変更される可能性があります。予めご了承ください。',
        3: '1. 変身動画を作りたい同じモデルのCrypkoを二つ選択して生成する。',
        4: '2. 生成ボタンをクリックする。',
        5: '3. 生成された動画をダウンロードする。',
      },
      travel: {
        desc: 'さまざまな世界にCrypkoを旅行させる機能です。トラベル写真やスターコインを報酬として獲得できます。',
        2: '* トラベルはベータ機能です、すべての仕様は変更される可能性があります。',
        3: 'トラベル画像に関して',
        4: '1. 各トラベル写真は、Crypkoのアルバムスロットを消費します。',
        5: '2. トラベル写真はCrypko本体に関連付けられており、Crypko本体が削除されると、そのトラベル写真も削除されます。',
        6: '3. トラベル画像のプライバシー設定（公開または非公開）は、Crypkoのプライバシー設定に従います。',
        7: '4. トラベル画像を保存した後、そのライセンスはCrypkoのライセンスに準拠します。',
      },
    },
    manage: {
      title: 'Crypko管理',
      detail: 'まだ開発中です。',
    },
    more: {
      title: 'その他',
      detail: 'まだ開発中です。',
    },
  },
  list: {
    create: 'リストを作成',
    creator: '作成者',
    delete: 'リストを削除',
    edit: 'リストを編集',
    settings: 'リスト設定',
    saveToList: 'リストに追加',
    placeholder: '名前',
    downloadConfirmTitle: 'ZIPダウンロード',
    downloadConfirmText: 'リスト内のCrypkoをダウンロードしますか？(20個以上の場合はダウンロード出来ません)',
    deleteConfirmTitle: '削除しますか？',
    deleteConfirmText: 'リスト内のCrypkoは削除されません。',
    pleaseLogin: 'ログインしてください。',
    empty: 'リストがありません。',
    selectFromCrypkos: '所持Crypkoから',
    selectFromLists: 'リスト(所持)から',
    selectFromCrossfuse: 'コラボ融合から',
  },
  userhome: {
    profile: 'プロフィール',
    pickUp: '注目',
    introduction: '自己紹介',
  },
  page404: {
    subtitle: 'お探しのページは見つかりませんでした。',
    desc:
      '申し訳ありません。お探しのページはアクセスができない状況にあります。',
    btn: '前のページに戻る',
  },
  workbench: {
    'under-dev': '開発中です。',
    select: {
      'screen-too-small': 'この機能を利用するためには、より大きな画面サイズが必要です。',
      'canvas-feature': 'キャンバス機能',
      'create-character': 'キャラクターを作る',
      'create-illust': '素材を作る',
    },
    generate: {
      'select-model': 'モデル選択',
      normalizePose: '標準ポーズで生成する',
      normalizedPose: '標準ポーズ',
    },
    fuse: {
      select: '元のCrypkoを選択',
      fuseResult: '融合結果',
      sameCrypko: '同じCrypkoを融合できません。',
      notSameModel: '同じモデルを選択してください。',
      notCorrectModel: '融合できないCrypkoが含まれています。',
      bothOthers: '自分のCrypkoを最低一つを選択してください。',
    },
    editMode: {
      'selection': '選択ツール',
      'rectangleCutout': '切り抜きツール',
      'freeCutout': 'なげなわツール',
      'drawing': 'ブラシツール',
      'eraser': '消しゴムツール',
      'colorBucket': 'バケツツール',
      'undo': '取り消し',
      'redo': 'やり直し',
    },
    keypoint: {
      'select': '元のCrypkoを選択',
      'load': '参照するCrypkoを選択',
      'toggle': 'ポイント表示',
      'choose-part': '部位を選択',
    },
    variation: {
      pose: 'ポーズ',
      hand: '手',
      hair: '髪',
      face: '顔',
      clothes: '服',
      strength: '強度',
      scale: '範囲',
      mode: 'モード',
      result: '派生結果',
    },
    interpolate: {
      notCorrectModel: 'FreesiaかFreesiaMモデルのCrypkoを選択してください。',
      sameCrypko: '同じCrypkoで変身動画を作れません。',
      notSameModel: '同じモデルのCrypkoを選択してください。',
      'remove-buffer': 'バッファーフレーム除外',
      'tweet-confirm': 'ダウンロードした動画ファイルをツイートに添付してください。',
    },
  },
  attrs: {
    erica: {
      'HairColors': '髪の色',
      'EyeColors': '目の色',
      'Others': '他の属性',
      'blonde_hair': '金髪',
      'brown_hair': '茶髪',
      'black_hair': '黒髪',
      'blue_hair': '青髪',
      'pink_hair': '桃髪',
      'purple_hair': '紫髪',
      'green_hair': '緑髪',
      'red_hair': '赤髪',
      'silver_hair': '銀髪',
      'white_hair': '白髪',
      'orange_hair': '橙髪',
      'aqua_hair': '碧髪',
      'grey_hair': '灰髪',
      'blue_eyes': '青眼',
      'red_eyes': '赤眼',
      'brown_eyes': '茶眼',
      'green_eyes': '緑眼',
      'purple_eyes': '紫眼',
      'yellow_eyes': '黄眼',
      'pink_eyes': '桃眼',
      'aqua_eyes': '碧眼',
      'black_eyes': '黒眼',
      'orange_eyes': '橙眼',
      'long_hair': 'ロングヘア',
      'short_hair': 'ショートヘア',
      'twintails': 'ツインテール',
      'twin_tail': 'ツインテール',
      'drill_hair': 'ドリルヘアー',
      'ponytail': 'ポニーテール',
      'dark_skin': '褐色肌',
      'blush': '赤面',
      'smile': '笑顔',
      'open_mouth': '開口',
      'hat': '帽子',
      'ribbon': 'リボン',
      'glasses': 'メガネ',
      'animal_ears': 'けもみみ',
    },
    freesia: {
      'Attributes': '属性',
      'Expressions': '表情',
      'HairStyles': '髪型',
      'HairColors': '髪の色',
      'EyeColors': '目の色',
      'Dev': 'Dev',
      ':d': '大笑い',
      '^_^': 'にっこり',
      ';)': 'ウインク(口閉じ)',
      ';d': 'ウインク(口開け)',
      ':<': 'への字口',
      ':o': 'Oの字口',
      '=_=': '¯\\_(ーワー)_/¯',
      'ahoge': 'アホ毛',
      'angry': '怒る',
      'animal_ears': 'けもみみ',
      'aqua_eyes': '碧目',
      'aqua_hair': '碧髪',
      'bare_shoulders': '肩出し',
      'black_dress': '黒い服',
      'black_eyes': '黒目',
      'black_hair': '黒髪',
      'blazer': 'ブレザー',
      'blonde_hair': '金髪',
      'blue_dress': '青い服',
      'blue_eyes': '青目',
      'blue_hair': '青髪',
      'blush': '赤面',
      'bob_cut': 'ボブ',
      'breasts': '胸の大きさ',
      'brown_eyes': '茶目',
      'brown_hair': '茶髪',
      'casual': '私服',
      'chibi': 'ちび',
      'child': '子供',
      'closed_eyes': '目閉じ',
      'closed_mouth': '口閉じ',
      'dark_skin': '褐色肌',
      'drill_hair': 'ドリルヘアー',
      'dutch_angle': 'ダッチアングル',
      'embarrassed': '照れる',
      'green_eyes': '緑目',
      'green_hair': '緑髪',
      'grey_eyes': '灰目',
      'grey_hair': '灰髪',
      'hair_over_one_eye': '片目隠し',
      'half-closed_eyes': '半目',
      'happy': '喜ぶ',
      'jitome': 'ジト目',
      'light_smile': '微笑み',
      'loli': 'ロリ',
      'long_hair': 'ロング',
      'messy_hair': 'ボサボサ',
      'naughty_face': 'いたずら',
      'one_eye_closed': '片目閉じ',
      'open_mouth': '口開き',
      'orange_eyes': '橙目',
      'orange_hair': '橙髪',
      'parted_bangs': 'センター分け',
      'pink_eyes': '桃目',
      'pink_hair': '桃髪',
      'pointy_ears': '長耳',
      'ponytail': 'ポニーテール',
      'purple_eyes': '紫目',
      'purple_hair': '紫髪',
      'red_eyes': '赤目',
      'red_hair': '赤髪',
      'sharp_teeth': 'ギザ歯',
      'short_hair': 'ショート',
      'short_twintails': 'ショットツインテール',
      'silver_hair': '銀髪',
      'smile': 'スマイル',
      'surprised': 'びっくり',
      'tareme': 'タレ目',
      'tsurime': 'ツリ目',
      'twintails': 'ツインテール',
      'two_side_up': 'ツーサイドアップ',
      'very_long_hair': 'スーパーロング',
      'wavy_hair': 'ウェービーヘア',
      'white_hair': '白髪',
      'white_shirt': '白',
      'yellow_eyes': '黄目',
      'younger': '若さ',
      'under-rim_eyewear': 'メガネ',
      'side_ponytail_left': 'サイドテール（左）',
      'side_ponytail_right': 'サイドテール（右）',
      'high_ponytail': 'ハイポニーテール',
      'low_ponytail': 'ローポニーテール',
      'short_ponytail': 'ショートポニーテール',
      'expressionless': '無表情',
      'crying_with_eyes_open': 'すすり泣き',
      'kimono': '着物',
      'frown': 'しかめっ面',
      'swimsuit': '水着',
    },
    'freesia-m': {
      'Attributes': '属性',
      'Expressions': '表情',
      'HairStyles': '髪型',
      'HairColors': '髪の色',
      'EyeColors': '目の色',
      'Dev': 'Dev',
      ':d': '大笑い',
      '^_^': 'にっこり',
      ';)': 'ウインク(口閉じ)',
      ';d': 'ウインク(口開け)',
      ':<': 'への字口',
      ':o': 'Oの字口',
      '=_=': '¯\\_(ーワー)_/¯',
      'ahoge': 'アホ毛',
      'angry': '怒る',
      'animal_ears': 'けもみみ',
      'aqua_eyes': '碧目',
      'aqua_hair': '碧髪',
      'bare_shoulders': '肩出し',
      'black_dress': '黒い服',
      'black_eyes': '黒目',
      'black_hair': '黒髪',
      'blazer': 'ブレザー',
      'blonde_hair': '金髪',
      'blue_dress': '青い服',
      'blue_eyes': '青目',
      'blue_hair': '青髪',
      'blush': '赤面',
      'bob_cut': 'ボブ',
      'breasts': '胸の大きさ',
      'brown_eyes': '茶目',
      'brown_hair': '茶髪',
      'casual': '私服',
      'chibi': 'ちび',
      'child': '子供',
      'closed_eyes': '目閉じ',
      'closed_mouth': '口閉じ',
      'dark_skin': '褐色肌',
      'drill_hair': 'ドリルヘアー',
      'dutch_angle': 'ダッチアングル',
      'embarrassed': '照れる',
      'green_eyes': '緑目',
      'green_hair': '緑髪',
      'grey_eyes': '灰目',
      'grey_hair': '灰髪',
      'hair_over_one_eye': '片目隠し',
      'half-closed_eyes': '半目',
      'happy': '喜ぶ',
      'jitome': 'ジト目',
      'light_smile': '微笑み',
      'loli': 'ロリ',
      'long_hair': 'ロング',
      'messy_hair': 'ボサボサ',
      'naughty_face': 'いたずら',
      'one_eye_closed': '片目閉じ',
      'open_mouth': '口開き',
      'orange_eyes': '橙目',
      'orange_hair': '橙髪',
      'parted_bangs': 'センター分け',
      'pink_eyes': '桃目',
      'pink_hair': '桃髪',
      'pointy_ears': '長耳',
      'ponytail': 'ポニーテール',
      'purple_eyes': '紫目',
      'purple_hair': '紫髪',
      'red_eyes': '赤目',
      'red_hair': '赤髪',
      'sharp_teeth': 'ギザ歯',
      'short_hair': 'ショート',
      'short_twintails': 'ショットツインテール',
      'silver_hair': '銀髪',
      'smile': 'スマイル',
      'surprised': 'びっくり',
      'tareme': 'タレ目',
      'tsurime': 'ツリ目',
      'twintails': 'ツインテール',
      'two_side_up': 'ツーサイドアップ',
      'very_long_hair': 'スーパーロング',
      'wavy_hair': 'ウェービーヘア',
      'white_hair': '白髪',
      'white_shirt': '白',
      'yellow_eyes': '黄目',
      'younger': '若さ',
      'under-rim_eyewear': 'メガネ',
      'side_ponytail': 'ポニーテール',
      'expressionless': '無表情',
      'crying_with_eyes_open': 'すすり泣き',
      'kimono': '着物',
      'frown': 'しかめっ面',
      'swimsuit': '水着',
    },
  },
  payment: {
    catalog: {
      cp: 'CP',
      features: '機能',
      model: 'モデル',
    },
    unit: {
      recovery: '{0} 分/CP',
      limit: '{0} CP',
      slot: '{0} 個',
    },
    plans: {
      Freemium: 'フリーミアム',
      Starter: 'スターター',
      Standard: 'スタンダード',
      Premium: 'プレミアム',
    },
    'slot-num': '{0}個',
    'license-num': '{0}画像',
    plan: 'プラン',
    trial: 'トライアル',
    '7-day-trial': '７日間トライアル',
    'in-trial': 'トライアル中',
    'with-trial': 'トライアルありで購入',
    'without-trial': 'トライアルなしで購入',
    'trial-desc': '7日間のトライアルが可能です！\n\n※すでにトライアル購入のためにカードが使用されている場合は、プラン解約させていただきます。',
    invoice: '請求書',
    unsubscribed: '解約済み',
    membership: 'メンバーシップ',
    'upgrade-plan': 'プランをアップグレードする',
    monthly: '毎月',
    'specified-commercial-transactions': '特定商取引法に基づく表記',
    'plan-features': {
      'cp-recovery': 'CP回復',
      'cp-limit': 'CP回復上限',
      slots: 'スロット数',
      gen: '生成',
      fuse: '融合',
      attrs: '属性',
      variation: '派生',
      canvas: 'キャンバス',
      video: '変身動画',
      'private-save': 'プライベート保存',
      download: '透かしなし画像ダウンロード',
      commercial: '画像の商用利用',
      chat: 'チャット',
      travel: 'トラベル',
    },
    'ip-country-not-support-billing': '現在、日本国内含め一部アジアの国からのお支払いのみがサポートされています。お住まいの地域がサポートされていないようですので、この先の画面に続くと、支払いが失敗する可能性があります。予めご了承ください。',
    'jpy': '¥{0}',
    'jpy-excluded-tax': '本体価格 ¥{0}',
    'current-plan': 'ご利用中のプラン',
    'feature-plan-unavailable': 'ご加入のプランではこの機能をご利用頂けません。',
    'freemium-crypko-model': 'このCrypkoと同じの生成モデルを利用するには、プランをアップグレードする必要があります。',
    'model-plan-restriction': 'このモデルを利用するには、プランをアップグレードする必要があります。',
    'view-plans': 'プランを見る',
    'tax-included': '（税込み）',
    'tax-excluded': '（税抜き）',
    'plan-ends': 'プラン終了日',
    'next-payment': 'プラン更新日',
    'thank-you': 'ご購入ありがとうございます。',
    'manage-subscription': 'サブスク管理',
    'campaign': 'キャンペーン',
    'first-month-only': '初月限定',
    'onetime': '都度購入',
    'recurring': '定期購入',
    '30-days': '30日',
    'discount': 'お得!',
    'paid-feature': '有料プラン機能',
    'plan-upgrade-msg': 'プランをアップグレードしますか？\n現在のプランで使用した時間に応じて、比例配分で計算された金額が請求されます。',
    'purchase-license': 'ライセンス購入',
    'purchased-license': 'ライセンス購入済み',
    'purchased': '購入済み',
    'purchase-license-msg': 'Crypko画面でライセンスをご購入ください。',
    'pay-by-email': '請求書はメールで送付されます。メールに記載されている手順に従い、お支払いを完了させてください。',
    'pay-by-checkout': '支払い画面にリダイレクトされます。このオプションを選択した場合、請求書の発行を対応しません。',
    'check-email-invoice': 'メールで請求書を送付されました。\n送信先：{0}\nメールに記載されている手順に従い、お支払いを完了させてください。',
    'invoice-paid': '支払いが完了しました',
    'coin-exchange-msg': '交換しますか？',
    notice: {
      'tax-included-price': '※税込価格です。',
      'tax-excluded-price': '※税抜価格です。',
      'payment-countries': '※日本、アメリカ、中国本土、台湾、香港、マカオ、韓国、オーストラリア、シンガポール、ニュージーランド、および27のEU諸国からの支払いをサポートしています。お支払いの請求先住所が上記にあることを確認してください。',
      'country-tax-rate': '※日本以外のユーザには各国の税率が適用されます。',
      'item-purchase-desc': '※各アイテムは、加入中のプランにかかわらず、ご購入いただくことができます。\n商用ライセンスは、加入中のプランにかかわらず、スロットに保存されているCrypko画像1枚ごとに購入可能です。商用ライセンスを購入されたCrypko画像については、加入中のプランにかかわらず、スロットに保存されている間、プライベート保存・透かしなし画像ダウンロードのほか、商用利用が可能となります。',
      'plan-cp-desc': '※各プランへの新規加入時は、CPが各プランに定めるCP上限の値に設定されます。但し、スタンダードプラン加入後にプレミアムプランへアップグレードした場合は、その時点のCPが引き継がれ、CPは変化しません。',
      'cp-recover-desc': '※各プランとも、CP残高が各プランに定めるCP回復上限の値を超える場合は、時間経過によるCPの回復は行われません。',
      'cp-6times-desc1': '※フリーミアムプランのCPの保有上限は、CP回復上限の値となります。スタンダードプラン及びプレミアムプランのCPの保有上限は、各プランに定めるCP回復上限の6倍となります。',
      'cp-6times-desc2': '※月額の定期購入をいただいたスタンダードプラン及びプレミアムプランの月次継続時には、その時点のCP残高に加え、各プランに定めるCP回復上限の値と同値のCPが付与されます。但し、CP残高がCPの保有上限値を超える場合は、この限りでありません。これにより、CPの利用期限は、実質的に付与から6ヶ月以内となります。',
      'plan-change-desc': '※月額の定期購入をいただいたスタンダードプラン及びプレミアムプランをキャンセルされた場合は、残りの有効期間が経過した時点で、自動的にフリーミアムプランに移行します。都度購入いただいたスタンダードプラン及びプレミアムプランは、有効期間が経過した時点で、自動的にフリーミアムプランに移行します。なお、フリーミアムプランに移行した後も、改めてスタンダードプラン及びプレミアムプランの月額定期購入又は都度購入を行っていただくことが可能です。',
      'canvas-fuse-desc': '※キャンバス機能を用いて作成されたCrypko画像は、融合機能及び属性機能の対象外となります。',
      'more': '※詳細は、{0}、{1}及び{2}をご確認ください。',
    },
  },
  notifications: {
    none: '通知はありません。',
    title: {
      info: 'お知らせ',
      fav: 'いいね',
      follow: '新しいフォロワー',
      fill: 'CP取得',
      slot: 'スロット取得',
      crossfuse: 'コラボ融合',
      crossfuse_result: 'コラボ融合',
      fav_album: 'いいね',
    },
    content: {
      fav: '{0} さんはあなたの Crypko {1} をいいねしました。',
      follow: '{0} さんにフォローされました。',
      fill: '{0} CPを取得しました。',
      slot: '{0} スロットを取得しました。',
      crossfuse: '{0} さんはあなたの Crypko {1} をコラボ融合しました。',
      'crossfuse-reward': '{0}をもらいました。',
      'crossfuse_result': '{0} さんの Crypko {1} とのコラボ融合結果を確認しよう： {2}。',
      fav_album: '{0} さんはあなたのアルバム {1} をいいねしました。',
    },
    'read-all': 'すべて既読',
  },
  search: {
    'no-data': '該当するタグがないため、名前で検索します。',
    'search-by-hash': 'ハッシュで検索...',
    'hint': 'ハッシュ、名前、タグ...',
  },
  link: {
    'privacy-policy': 'https://www.preferred.jp/ja/policy/',
  },
  changelogs: {
    230614: {
      img: '/images/updates/230614/banner-ja.jpg',
      'vid-url': 'https://www.youtube.com/watch?v=YFmUZitWVAQ',
      title: 'チャット機能',
      desc: 'チャット機能をアルファリリースしました。詳しくは動画をご覧ください。',
    },
    230712: {
      img: '/images/updates/230712/banner.jpg',
      title: 'Gerbera(M)のポーズ派生',
      desc: '派生機能でGerbera(M)モデルCrypkoの色んなポーズを生成できるようになりました。\n※有料機能',
    },
    230720: {
      title: '【期間終了】水着属性',
      desc: 'Freesia(M)の水着属性調整は8月末まで限定公開されます。お気に入りのCrypkoに水着を着せてみましょう！\n※有料機能',
    },
    230810: {
      img: '/images/updates/230810/banner-ja.jpg',
      title: '【期間終了】マスコット（水着）引き換えキャンペーン',
      desc: '限定期間内マスコット水着ver.は合計1000CPで引き換えられます。キャンペーン対象は全ユーザーです。Crypkoのマスコットに水着を着せてチャットしてみよう！',
    },
    230930: {
      img: '/images/updates/230930/banner.jpg',
      title: '新しい属性',
      desc: '属性機能に新しい属性 ¯\\_(ーワー)_/¯ を追加しました！\n※有料機能',
    },
    231020: {
      img: '/images/updates/231020/banner.jpg',
      title: 'ランキング',
      desc: 'ギャラリーでユーザーランキングが追加されました🔥',
    },
    231027: {
      img: '/images/updates/231027/banner.jpg',
      title: 'タグレア度',
      desc: 'Freesiaモデルの生成結果にタグレア度が表示されるようになりました！\n※タグレア度の計算方法を調整しました。',
    },
    240116: {
      img: '/images/updates/240116/banner-ja.jpg',
      title: 'デイリータスクと通貨',
      desc: '新しい{0}スターが追加され、CPやスロットと交換できます。デイリータスクを完了して、{0}スターを獲得しましょう！',
    },
    240321: {
      img: '/images/help/travel.jpg',
      title: 'Crypkoトラベル',
      desc: '新しいベータ機能トラベルが公開されました！あなただけのキャラクターにさまざまな世界を旅させて、旅の写真を集めましょう！',
    },
    240520: {
      img: '/images/updates/240520/orbis.jpg',
      title: '新トラベルワールド',
      desc: '新しいトラベルワールドOrbisが追加されました！リアルワールドと似た並行世界に旅立ちましょう！',
    },
    240525: {
      img: '/images/updates/240525/leg.jpg',
      img2: '/images/updates/240525/gerbera.jpg',
      title: 'トラベル写真生成の修正',
      desc: 'トラベル写真生成の不具合が修正されました。\n1. キャラクターの脚がよく水や土の中に埋まる問題。',
      desc2: '2. Gerberaモデルのトラベル写真が崩壊する問題。',
    },
    240708: {
      img: '/images/updates/240708/banner.jpg',
      title: 'トラベルのキャラセリフ',
      desc: 'トラベル機能に一定の確率でメッセージ付きのトラベル写真を生成する機能が追加されました！\n※有料プランのユーザーはこの機能を制御できます。',
    },
    240726: {
      title: '新トラベルワールド',
      desc: '新しいトラベルワールドCybritusが追加されました！サイバネティクスが支配する未来都市型の世界を探索しましょう！\n※有料プラン向けのワールドです。',
    },
    240823: {
      title: '新モデルHibiscus',
      desc: '新しい生成モデルHibiscusが追加されました！新しいモデルでCrypkoをたくさん生成しましょう！',
    },
  },
  rank: {
    fav_rank_week: 'いいね（週間）',
    crossfuse_rank_week: 'コラボ融合（週間）',
    fav_rank_all: 'いいね（総合）',
    follower_rank: 'フォロワー',
  },
  dailyTask: {
    title: 'デイリータスク',
    action: {
      fav: 'いいねを{0}回送る',
      gen: '生成を{0}回行う',
      fuse: '融合を{0}回行う',
      save: '保存を{0}回行う',
      crossfuse_set: '新しいCrypkoをコラボ融合で公開する',
      travel: 'トラベルを{0}回行う',
    },
  },
  play: {
    travel: {
      title: 'トラベル',
      desc: '✈️ 色んな世界を旅して、旅行写真を集めましょう。',
    },
    mascot: {
      title: 'マスコット',
      desc: '💬 Crypkoマスコットと交流しましょう。',
    },
  },
  introduction: {
    travel: {
      desc: 'あなただけのキャラクターを様々な世界へ旅させ、唯一無二の旅行写真を集めましょう！',
      contact: '※任意のキャラクターイラストから背景付きイラストの生成機能をビジネス向けにご提供しております。詳しくはお問い合わせください。',
    },
    release: {
      title: 'Crypko 正式リリース',
      start: 'アニメキャラクター生成プラットフォーム Crypko™',
      'start-2': 'v1.0 正式リリース',
      'notice-on-terms': '※Crypkoは編集機能を備えており、AIが生成するアニメキャラクターの髪、顔、服装、スタイルなどをユーザーが編集することが可能です。ユーザーの皆さまは{0}の遵守をお願いいたします。',
      'terms-link-text': 'Crypko利用規約',
      signup: '今すぐキャラクターを作る',
    },
    generation: {
      title: 'アニメキャラクター生成AI',
      detail: 'Crypko は深層学習の1種であるGAN（Generative Adversarial Network）を利用し、高品質の2Dキャラクターの立ち絵を自動生成することができます。',
      text: 'GANは、画像から特徴量を学習することで、生成画像の間を滑らかに変換することができます。',
    },
    memes: {
      title: 'スマホアプリMEMES',
      detail: 'Crypko のコアテクノロジーから生まれるスマートフォン向けアプリケーション『MEMES』、Apple App Store/Google Play Store にて無料配信中。',
      access: 'MEMES 公式サイト',
    },
    'chara-design': {
      title: 'キャラクターデザイン',
      detail: 'アニメキャラクターをAIで自動生成する過程において、ユーザーの好みや意図を反映させることができます。',
      detail2: 'イメージ通りのキャラクターが具象化されます。',
    },
    animation: {
      title: 'さらなる創作の可能性',
      detail: '絵のスキルがなくても、生成されたアニメキャラクターに滑らかな動きをつけたり、編集を加えたりすることも可能です。',
    },
  },
  travel: {
    'crypko-travel': 'Crypkoトラベル',
    actions: 'アクション',
    photos: 'トラベル写真',
    'go-travel': 'トラベルへ',
    'obtain-photos': 'トラベルで写真をもらおう',
    'claim-with-save': '確認 & Crypkoに保存',
    'save-album': 'Crypkoに保存する',
    saved: '保存済み',
    worlds: 'ワールド',
    'worlds-info': {
      Arcadia: '自然豊かなファンタジー世界',
      Orbis: 'リアルワールドと似た並行世界',
      Cybritus: 'サイバネティクスが支配する未来都市型の世界',
    },
    history: '履歴',
    'history-display': '直近１ヶ月の履歴のみ表示する',
    state: {
      traveling: 'トラベル中',
      generating: 'トラベル中',
      unclaimed: '結果確認',
      failed: '失敗',
    },
    'generating-msg': 'トラベル写真を生成しています。通常は20秒以内に完了します。',
    'failed-msg': 'トラベル写真生成が失敗しました。消費された {0} CP が返却されます。',
    'has-travel-msg': '進行中のトラベルの結果が確認された後、新しいトラベルを始められます。',
    'start-travel-msg': 'ワールドを選択してからトラベルを始めてください。',
    'please-select-crypko': 'Crypkoを選択してください',
    duration: 'トラベル時間',
    'fast-travel': '時間短縮',
    'fast-travel-notice': '※ トラベル写真の生成には、サーバーの負荷に応じて約20秒かかることにご注意ください。',
    'first-travel-notice': '※ 時間短縮は最初のトラベルでコストなしで最大に設定されています。',
    'view-album-crypko': 'Crypkoページで見る',
    'remove-album': 'Crypkoから削除する',
    'remove-msg': 'メッセージを削除する',
    'insufficient-album-slot': 'アルバムスロット不足',
    'coin-exchange-album-msg': '{0}スターコインを消費してアルバムスロット1つと交換しますか？',
    'start-now': '今すぐ出発',
    'welcome-desc': 'キャラクターイラストを作ろう',
    'gen-msg': 'キャラセリフ',
    'gen-msg-desc': '無料プランでも一定の確率でセリフ生成されますが、有料プランにアップグレードすると、セリフ生成機能を自由にご利用いただけます。',
    'paid-world': 'ワールドをアンロックするには、有料プランにアップグレードしてください。',
  },
  survey: {
    'submit-error': '失敗しました。すでにアンケートに回答している可能性があります。',
    'help-us-improve': 'アンケートにご協力ください！',
    'complete-msg': '500コインが付与されました！ストアでCPやスロットと交換できます。',
  },
}
