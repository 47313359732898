<template>
  <v-app>
    <v-app-bar
      app
      clipped-right
      clipped-left
      dense
      flat
      :style="blurryAppBar ? 'backdrop-filter: blur(4px); -webkit-backdrop-filter: blur(4px); background: #fff3;' : 'background: #fff;'"
    >
      <logo-icon />
      <v-divider
        v-if="user && $vuetify.breakpoint.smAndUp"
        inset
        vertical
      />
      <div
        class="flex-grow-1 d-flex align-center"
        style="overflow-x: auto; overflow-y: hidden; gap: 0.5rem;"
      >
        <v-btn-toggle
          v-if="user && $vuetify.breakpoint.smAndUp"
          borderless
          background-color="transparent"
          dense
        >
          <v-btn
            v-for="(btn, btnName) in NAV_BTN"
            :key="btnName"
            :to="btn.to"
            class="rounded-pill px-2"
            color="grey darken-3"
            :ripple="false"
            text
          >
            <v-icon
              v-show="$route.name && btn.to && $route.name.startsWith(btn.to.name)"
              class="mr-1"
              color="primary"
              size="16"
            >
              {{ btn.icon }}
            </v-icon>
            <span>{{ btnName }}</span>
          </v-btn>
        </v-btn-toggle>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-2 text-caption px-1"
              text
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              10:00<br>
              Maintain
            </v-btn>
          </template>
          <span>Maintenance at 9/30 10:00~12:00 (JST)</span>
        </v-tooltip> -->
        <!-- <survey-widget /> -->
      </div>
      <!-- <v-spacer /> -->
      <stamina-widget
        v-if="user"
      />
      <user-avatar
        v-if="user"
        :user="user"
        size="36px"
        badge
      />
      <v-btn
        v-else
        class="px-2"
        text
        @click="onSignupClick"
      >
        {{ $t('header.router.signup-signin') }}
      </v-btn>
    </v-app-bar>
    <router-view />
    <v-bottom-navigation
      v-if="user && $vuetify.breakpoint.xsOnly"
      app
      color="primary"
      grow
    >
      <v-btn
        v-for="(btn, btnName) in NAV_BTN"
        :key="btnName"
        :to="btn.to"
      >
        <span>{{ btnName }}</span>
        <v-icon>{{ btn.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <stamp-card-dialog v-if="user" />
    <system-notification-dialog v-if="user" />
    <daily-task-dialog v-if="user" />
    <confirm-terms-dialog />
    <!-- Trial Banner -->
    <v-img
      v-if="showTrialBanner"
      class="mx-auto trial-banner"
      :class="{
        'trial-banner__bottom-margin': $vuetify.breakpoint.xsOnly && user
      }"
      :src="`/images/trial/${lang}/banner.jpg`"
      contain
      max-width="100%"
      @click="() => $router.push({name: 'plans'})"
    >
      <v-btn
        absolute
        class="trial-btn"
        color="#0003"
        fab
        width="28"
        height="28"
        elevation="0"
        @click.stop="() => trialBanner = false"
      >
        <v-icon color="white">
          mdi-close
        </v-icon>
      </v-btn>
    </v-img>
  </v-app>
</template>

<script>
import {
  NAV_BTN, NAV_TYPE, DEV_MODE,
  SENTRY_ERROR_MSG_BLACKLIST,
} from './utils/constants'
import { mapGetters, mapState } from 'vuex'
import '../public/fonts/fonts.css'
import LogoIcon from '@/widgets/LogoIcon'
import UserAvatar from '@/widgets/UserAvatar'
import { mapFields } from 'vuex-map-fields'
import { getUserLocale } from '@/i18n'
import StaminaWidget from '@/widgets/StaminaWidget'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import Vue from 'vue'
import router from './router'
import ConfirmTermsDialog from '@/widgets/ConfirmTermsDialog'
import StampCardDialog from '@/widgets/StampCardDialog'
import SystemNotificationDialog from '@/widgets/SystemNotificationDialog.vue'
import GAUtils from './utils/ga'
import User from './utils/user'
import DailyTaskDialog from './widgets/DailyTaskDialog.vue'
import Dialog from '@/utils/dialog'
// import SurveyWidget from './widgets/SurveyWidget.vue'

export default {
  name: 'app',
  components: {
    StaminaWidget,
    LogoIcon,
    UserAvatar,
    ConfirmTermsDialog,
    StampCardDialog,
    SystemNotificationDialog,
    DailyTaskDialog,
    // SurveyWidget,
},
  data () {
    return {
      NAV_TYPE,
      tab: null,
      trialBanner: true,
    }
  },
  computed: {
    ...mapFields(['lang']),
    ...mapFields('workbench', ['drawerRight', 'drawerLeft']),
    ...mapGetters('workbench', ['windowTooNarrow']),
    ...mapState('user', ['user']),
    ...mapState('notification', ['unread']),
    blurryAppBar () {
      return this.$route.name === 'introduction' || (this.$route.fullPath.includes('/auth/'))
    },
    coins () {
      return this.user?.coins
    },
    NAV_BTN () {
      const UPDATED_NAV_BTN = { ...NAV_BTN }
      const id = this.user ? this.user.id : 0
      UPDATED_NAV_BTN[NAV_TYPE.LIBRARY].to.params = { id }
      return UPDATED_NAV_BTN
    },
    showTrialBanner () {
      const inRoute = ['gallery', 'introduction']
      return this.trialBanner && inRoute.includes(this.$route.name) && (!this.user || this.user.isFreemium()) && this.$route.name !== 'introduction'
    },
  },
  watch: {
    coins (val, old) {
      if (val === undefined || old === undefined) {
        // login & logout, do nothing.
        return
      }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        notation: 'standard', 
        signDisplay: 'exceptZero',
      })
      const delta = formatter.format(val - old)
      if (old > val) {
        Dialog.showSuccessDialog(this.$t('dialog.spend-coin', {
          delta: delta,
          total: val,
        }))
        return 
      }

      Dialog.showSuccessDialog(this.$t('dialog.get-coin', {
        delta: delta,
        total: val,
      }))
    },
  },
  created () {
    if (!(this.user && this.user.data && this.user.data.lang)) {
      const uaNoRedirectList = Object.freeze([
        'googlebot',
        'Googlebot',
        'Twitterbot',
        'facebookexternalhit',
        'Slackbot',
      ])
      this.lang = uaNoRedirectList.some((ua) => navigator.userAgent.indexOf(ua) > -1) ?
        (document.querySelector('html').getAttribute('lang') === 'zh-CN' ? 'cn' : document.querySelector('html').getAttribute('lang'))
        : getUserLocale()
    } else {
      this.lang = this.user.data.lang
    }
    this.$i18n.locale = this.lang
    document.querySelector('html').setAttribute('lang', this.$i18n.locale === 'cn' ? 'zh-CN' : this.$i18n.locale)
    if (this.user && this.user.data && this.user.data.lang !== this.lang) {
      User.patch(this.user.id, {
        data: {
          lang: this.lang,
        },
      })
    }
    this.handleCookieConsent()
    // Get the number of unread notifications when first loaded
    this.fetchNotificationsCount()

    this.$store.dispatch('workbench/setFingerprint')

    // convert url locale to this.lang
    const locale = ['ja', 'en', 'cn'].includes(window.location.pathname.split('/')[1]) ? window.location.pathname.split('/')[1] : ''
    if (locale !== this.lang) {
      let pathname = window.location.pathname
      if (locale) {
        pathname = pathname.slice(`/${locale}`.length)
      }
      const dest = `/${this.lang}${pathname}${window.location.search}`
      this.$router.push(dest)
    }
  },
  methods: {
    onSignupClick () {
      this.$router.push({
        name: 'signup-signin',
        query: { redirect: this.$route.query.redirect },
      })
    },
    fetchNotificationsCount () {
      if (this.user) {
        this.$store.dispatch('notification/getUnreadCount')
      }
    },
    handleCookieConsent () {
      const user = this.user
      window.cookieConsentInit(window.cookieConsent)({
        onChange (result) {
          if (result.performance) {
            // Install Google Tag Manager
            window['dataLayer'] = window['dataLayer'] || []
            window['dataLayer'].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js',
            })


            const f = document.getElementsByTagName('script')[0]
            const j = document.createElement('script')
            j.async = true
            if (DEV_MODE) {
              j.src = 'https://www.googletagmanager.com/gtag/js?id=G-3PSDJCB818'
            } else {
              j.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-NZ2FR7B'
            }
            f.parentNode.insertBefore(j, f)

            // Enable track events by GA4
            // eslint-disable-next-line no-inner-declarations
            function gtag () { window['dataLayer'].push(arguments) }
            gtag('js', new Date())
            if (DEV_MODE) {
              gtag('config', 'G-3PSDJCB818', { debug_mode: true })
            } else {
              gtag('config', 'G-RVW2HF5QNS')
            }
            // set user info
            if (user) GAUtils.setUserProps(user)

            // Setup Sentry
            if (!DEV_MODE) {
              Sentry.init({
                Vue,
                dsn: 'https://ecaf4e0ae1ab4c5a8f1ab68acc51006a@o394275.ingest.sentry.io/6183372',
                integrations: [
                  new BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                    tracingOrigins: [
                      'localhost',
                      'beta-api.crypko.ai',
                      // 'api-beta.crypko.ai',
                      /^\//,
                    ],
                  }),
                ],
                // Do not capture transactions for performance monitoring.
                tracesSampleRate: 0.0,
                beforeSend (event, hint) {
                  const error = hint.originalException
                  if (
                    error &&
                    error.message &&
                    SENTRY_ERROR_MSG_BLACKLIST.some(rx => rx.test(error.message))
                  ) {
                    return null
                  }
                  return event
                },
              })
            }
          }
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.v-application {
  background: map-get($colors, workbench-content-bg);
}

.v-app-bar {
  ::v-deep {
    .v-tab {
      font-size: 1rem;
    }
  }
  z-index: 175;
}

::v-deep {
  .v-app-bar .v-toolbar__content {
    gap: 0.75rem;
  }
}

.v-btn-toggle > .v-btn {
  height: 32px;
}

.v-btn-toggle > .v-btn.v-btn--active {
  background-color: rgba(242, 118, 119, 0.1) !important;
  color: #f27677 !important;
}

.v-btn--active::before {
  opacity: 0 !important;
}

// Note that deep selector has higher priority than global styles
::v-deep {
  .v-btn:focus::before {
    opacity: 0 !important;
  }

  .v-navigation-drawer {
    // set navigation drawer color
    &,
    .v-tabs,
    .v-tabs-bar,
    .v-tabs-items {
      background-color: map-get($colors, workbench-nav-drawer);
      // border-color: map-get($colors, workbench-nav-drawer);
    }
  }

  .fill-width {
    width: 100%;
  }

  .bg-card-shadow {
    box-shadow: map-get($shadows, background-section) !important;
  }

  .max-body-width {
    max-width: map-get($sizes, main-body-max-width);
  }

  .trial-banner {
    z-index: 7;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;

    &__bottom-margin {
      bottom: 56px;
    }
  }

  .trial-btn {
    right: 4px;
    top: 4px;
  }

  .workbench-container {
    max-width: map-get($sizes, main-body-max-width);
  }
}
</style>

<style lang="scss">
@import "@/scss/_helper_classes.scss";
@import "@/scss/_global.scss";
@import "@/scss/_vuetify_overrides.scss";

.v-application--wrap {
  min-height: 100svh;
}
</style>
