<template>
  <v-dialog
    v-model="changelogDialog"
    content-class="changelog-dialog"
    max-width="800px"
  >
    <v-card style="overflow: hidden;">
      <v-toolbar elevation="0">
        <v-toolbar-title>
          {{ $t('header.avatar.system-notification') }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="changelogDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div
        v-if="$vuetify.breakpoint.smAndUp"
        style="display: flex; max-height: 500px; min-height: 500px;"
      >
        <div style="display: flex; flex-direction: column; flex-grow: 0; flex-shrink: 0; overflow-y: auto;">
          <v-tabs
            center-active
            vertical
          >
            <v-tab
              v-for="(component, i) in components"
              :key="component"
              class="text-caption justify-start"
              style="max-width: 200px;"
              @click="currentDate = getDate(component)"
            >
              <div class="d-flex flex-column text-start overflow-hidden">
                <div class="text-caption">
                  {{ getFormatedDate(component) }}
                  <v-chip
                    v-if="i == 0"
                    class="px-2"
                    color="secondary"
                    x-small
                  >
                    new
                  </v-chip>
                </div>
                <div class="text-truncate text-no-wrap font-weight-bold">
                  {{ getTitle(component) }}
                </div>
              </div>
            </v-tab>
          </v-tabs>
        </div>
        <div style="overflow-y: auto; background-color: #f9f9f9">
          <template v-for="(component, i) in components">
            <component
              :is="component"
              :key="i"
              style="padding-bottom: 2rem;"
              :style="[{
                display: currentDate === getDate(component) ? 'block' : 'none',
              }]"
            />
          </template>
        </div>
      </div>
      <div v-else>
        <v-tabs
          left
          show-arrows
        >
          <v-tab
            v-for="(component, i) in components"
            :key="component"
            class="justify-start"
            @click="currentDate = getDate(component)"
          >
            <div class="d-flex flex-column text-start">
              <div class="text-caption">
                {{ getFormatedDate(component) }}
                <v-chip
                  v-if="i == 0"
                  class="px-2"
                  color="secondary"
                  x-small
                >
                  new
                </v-chip>
              </div>
              <div
                class="text-truncate text-no-wrap overflow-hidden"
                style="max-width: 200px;"
              >
                {{ getTitle(component) }}
              </div>
            </div>
          </v-tab>
        </v-tabs>
        <div
          class="pb-4"
          style="background-color: #f9f9f9; max-height: calc(90vh - 48px - 56px); overflow: auto;"
        >
          <template v-for="(component, i) in components">
            <component
              :is="component"
              :key="i"
              :style="[{
                display: currentDate === getDate(component) ? 'block' : 'none',
              }]"
            />
          </template>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { LATEST_CHANGELOG_VERSION } from '@/utils/constants'
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'
import Update230810 from '@/components/changelogs/Update230810'
import Update230930 from '@/components/changelogs/Update230930'
import Update231020 from '@/components/changelogs/Update231020'
import Update231027 from '@/components/changelogs/Update231027'
import Update240116 from '@/components/changelogs/Update240116'
import Update240321 from '@/components/changelogs/Update240321'
import Update240520 from '@/components/changelogs/Update240520'
import Update240525 from '@/components/changelogs/Update240525'
import Update240708 from '@/components/changelogs/Update240708'
import Update240726 from '@/components/changelogs/Update240726'
import Update240823 from '@/components/changelogs/Update240823'


export default {
  components: {
    Update230810,
    Update230930,
    Update231020,
    Update231027,
    Update240116,
    Update240321,
    Update240520,
    Update240525,
    Update240708,
    Update240726,
    Update240823,
  },
  data: () => ({
    currentDate: LATEST_CHANGELOG_VERSION,
    components: [
      'Update240823',
      'Update240726',
      'Update240708',
      'Update240525',
      'Update240520',
      'Update240321',
      'Update240116',
      'Update231027',
      'Update231020',
      'Update230930',
      'Update230810',
    ],
  }),
  computed: {
    ...mapFields(['changelogDialog']),
    ...mapState('user', ['user']),
  },
  watch: {
    user () {
      this.checkAndShowDialog()
    },
    changelogDialog (val) {
      if(val) {
        document.documentElement.style.overflow = 'hidden'
      } else {
        document.documentElement.style.overflow = 'auto'
      }
    },
  },
  created () {
    this.checkAndShowDialog()
  },
  methods: {
    getDate (comp) {
      return comp.replace('Update', '')
    },
    getFormatedDate (comp) {
      const date = this.getDate(comp)
      return `${date.slice(2, 4)}-${date.slice(4)}`
    },
    getTitle (comp) {
      return this.$t(`changelogs.${this.getDate(comp)}.title`)
    },
    checkAndShowDialog () {
      if (this.user && localStorage.getItem('changelogVersion') !== LATEST_CHANGELOG_VERSION) {
        this.changelogDialog = true
        localStorage.setItem('changelogVersion', LATEST_CHANGELOG_VERSION)
      }
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-carousel__controls__item {
    color: rgb(190, 190, 190) !important;
  }

  .v-carousel__item {
    overflow: auto;
  }
}
</style>

<style lang="scss">
.changelog-dialog {
  border-radius: 24px;
  margin: 12px;
}
.v-dialog.changelog-dialog.v-dialog--active{
  overflow-y: hidden !important;
}
</style>