import _ from 'lodash'
import { APIRequest, downloadFromUrl } from '.'
import LOCAL_CRYPKOS from '../../public/emote/crypko_emote_list.json'
import {
  ITEMS_PER_PAGE, SLOTS_NUM, DESCENDING_IMG_SIZES,
  ASCENDING_DISPLAY_SIZES, IMG_SIZES, GALLERY_ROUTE_NAME, GALLERY_VIEW_NAME,
} from './constants'
import OSSUtils from './oss'

export const COLOR_MAP = {
  'blue eyes': '#79c7e7',
  'red eyes': '#e57f7d',
  'brown eyes': '#939393',
  'green eyes': '#86cd7b',
  'purple eyes': '#86cd7b',
  'yellow eyes': '#f1bc6c',
  'pink eyes': '#e57f7d',
  'aqua eyes': '#79c7e7',
  'black eyes': '#939393',
  'orange eyes': '#f1bc6c',
}

const TEMP_MARKER = 't'
const DELIMITER = '~'
const HASH_LEN = 32

export default class Crypko {
  constructor (data) {
    if (_.isEmpty(data)) {
      console.error('Do not create empty Crypko!')
      return
    }


    Object.assign(this, data)

    // if (!this.src) {
    //   // backward compatibility
    //   for (const size of DESCENDING_IMG_SIZES) {
    //     if (this.sizes.includes(size)) {
    //       this.src = this.getSrc(size)
    //       break
    //     }
    //   }
    // }
    // if (this.sizes) {
    //   this.pyramid = this.sizes.map(size => this.getSrc(size))
    // }
  }

  // fetch all data of a crypko
  async fetch () {
    Object.assign(this, await Crypko.fetch(this.hash))
  }

  // update the Crypko with given data
  // TODO: make sure this trigger reactivity hook
  update (data) {
    Object.assign(this, Crypko.parse({
      ...this,
      ...data,
    }))
  }

  async getSrc (size) {
    return await OSSUtils.getCrypkoSrcBySize(this, size)
  }

  async getLargestSrc () {
    for (const size of DESCENDING_IMG_SIZES) {
      if (this.sizes.includes(size)) {
        return await this.getSrc(size)
      }
    }
    throw new Error(`No display size found for Crypko ${this.hash}`)
  }

  // get the src of the smallest image that is larger than `size`
  async getSmallestSrc (minSize) {
    if (!this.sizes) return this.src
    for (const size of ASCENDING_DISPLAY_SIZES) {
      // use the smallest image that is large enough
      if (IMG_SIZES[size] >= minSize && this.sizes.includes(size)) {
        return await this.getSrc(size)
      }
    }
    // incase the size is too large, return the largest available
    return await this.getLargestSrc()
  }

  // background color
  get bgColor () {
    return Crypko.MODEL_COLOR_MAP.erica
    // return Crypko.MODEL_COLOR_MAP[this.model]
    //   || console.log(`${this.model} does not have a color`) || Crypko.MODEL_COLOR_MAP.erica
  }

  get isEmote () {
    return !!this.emoteUrl
  }

  get isPlaceholder () {
    return this.hash === Crypko.PLACEHOLDER.hash
  }

  // get lazySrc () {
  //   if (this.type === CRYPKO_TYPE.TEMPLATE) return null
  //   return this.getSrc('TH')
  // }

  async segmentationSrc () {
    if (this.isPlaceholder) return null
    let subfolder
    if (this.temp) {
      subfolder = 'private/temp_crypkos'
    } else if (this.public) {
      subfolder = 'public/crypkos'
    } else {
      subfolder = 'private/crypkos'
    }
    const key = `users/${this.owner.id}/${subfolder}/${this.hash}/segmentation/LG.png`
    return await OSSUtils.getUrl({ key })
  }

  get routeHash () {
    /* When temp is true, add a 't' letter to the hash of the crypko
      This is done so that the front-end can figure out which API to query
      but be aware that the back-end never use the 't' representation
    */
    let { hash } = this
    if (this.temp) {
      hash += DELIMITER + TEMP_MARKER
    }
    return hash
  }

  get route () {
    return {
      name: this.isEmote ? 'local-crypko' : 'crypko',
      params: { hash: this.hash },
    }
  }

  static PLACEHOLDER = new Crypko({
    hash: 'placeholder',
    src: '/images/DefaultProfile.jpg',
  })

  static MODEL_COLOR_MAP = {
    'erica': '#79c7e7',
    'edit-v1': '#e57f7d',
  }

  static parse (data) {
    if (data === null || data === undefined) return null
    console.assert(typeof data === 'object', data)
    const crypko = new Crypko(data)

    // parse parents & children
    if (crypko.parents) {
      crypko.parents = crypko.parents.map(
        p => Crypko.parse(p))
    }
    if (crypko.children) {
      crypko.children = crypko.children.map(
        c => Crypko.parse(c))
    }
    if (crypko.owner_crypkos) {
      crypko.owner_crypkos = crypko.owner_crypkos.map(
        c => Crypko.parse(c))
    }
    return crypko
  }

  static async fetch (hash) {
    if (!hash) return null
    let temp = false, marker
    if (hash.length > HASH_LEN) {
      [hash, marker] = hash.split(DELIMITER)
      if (marker === TEMP_MARKER) {
        temp = true
      }
    }
    const route = temp ? `/temp-crypkos/${hash}/`
      : `/crypkos/${hash}/`
    const res = await new APIRequest(route, null, 'get').send()
    const crypko = Crypko.parse({
      ...res.data,
      temp,
    })
    return crypko
  }

  static async download (hashes) {
    if (typeof hashes === 'string') {
      hashes = [hashes]
    }
    if (!hashes.length) return
    const request = new APIRequest(`/crypkos/download/`, {
      hashes,
    })
    const { data, status } = await request.send()
    if (status !== 200) {
      throw new Error('API Error')
    }

    if (hashes.length === 1) {
      await downloadFromUrl(data.url, `${hashes[0]}.jpg`)
    } else {
      await downloadFromUrl(data.url, `${hashes.length}crypkos.zip`)
    }
  }

  static fromLocal (name) {
    return new Crypko({
      hash: name,
      emoteUrl: `/emote/data/${name}.emtbytes`,
      src: `/emote/preview/${name}.png`,
      // lazySrc: `/emote/thumbnail/${name}.png`,
    })
  }

  // Empty Crypko can be a null or a placeholder crypko
  static isEmpty (crypko) {
    if (Array.isArray(crypko)) {
      return crypko.every(Crypko.isEmpty)
    }
    return !crypko || crypko.isPlaceholder
  }

  static async getList (params) {
    const request = new APIRequest('/crypkos/', null, 'get')
    request.query = params
    const { data: { results: crypkos, count } } = await request.send()
    const parsedCrypkos = crypkos.map(crypko => Crypko.parse(crypko))
    return {
      crypkos: parsedCrypkos,
      count,
    }
  }

  static async getGallery () {
    const request = new APIRequest('/gallery/', null, 'get')
    const { data } = await request.send()
    const parsedData = {}
    Object.entries(data).forEach(([k, v]) => parsedData[k] = v.map(crypko => Crypko.parse(crypko)))
    return parsedData
  }

  static async getGalleryHome () {
    const request = new APIRequest('/gallery/home/?views=trend,album', null, 'get')
    const { data } = await request.send()
    const parsedData = {}
    Object.entries(data).forEach(([k, v]) => parsedData[k] = v.map(crypko => Crypko.parse(crypko)))
    return parsedData
  }
  static async getGalleryCrypko () {
    const views = ['crossfuse', 'following', 'chattable', 'gerbera', 'freesia', 'erica', 'hibiscus'].join(',')
    const request = new APIRequest(`/gallery/home/?views=${views}`, null, 'get')
    const { data } = await request.send()
    const parsedData = {}
    Object.entries(data).forEach(([k, v]) => parsedData[k] = v.map(crypko => Crypko.parse(crypko)))
    return parsedData
  }

  static async getGalleryView (routeName, params = {}) {
    const request = new APIRequest('/gallery/', null, 'get')
    request.query = params
    switch (routeName) {
      case GALLERY_ROUTE_NAME.TREND:
        request.query.view = GALLERY_VIEW_NAME.TREND
        break
      case GALLERY_ROUTE_NAME.CHATTABLE:
        request.query.view = GALLERY_VIEW_NAME.CHATTABLE
        break
      case GALLERY_ROUTE_NAME.CROSSFUSE:
        request.query.view = GALLERY_VIEW_NAME.CROSSFUSE
        break
      case GALLERY_ROUTE_NAME.FOLLOWING:
        request.query.view = GALLERY_VIEW_NAME.FOLLOWING
        break
      case GALLERY_ROUTE_NAME.HIBISCUS:
        request.query.view = GALLERY_VIEW_NAME.HIBISCUS
        break
      case GALLERY_ROUTE_NAME.GERBERA:
        request.query.view = GALLERY_VIEW_NAME.GERBERA
        break
      case GALLERY_ROUTE_NAME.FREESIA:
        request.query.view = GALLERY_VIEW_NAME.FREESIA
        break
      case GALLERY_ROUTE_NAME.FREESIA_M:
        request.query.view = GALLERY_VIEW_NAME.FREESIA_M
        break
      case GALLERY_ROUTE_NAME.ERICA:
        request.query.view = GALLERY_VIEW_NAME.ERICA
        break
      case GALLERY_ROUTE_NAME.CANVAS:
        request.query.view = GALLERY_VIEW_NAME.CANVAS
        break
      default:
        throw Error('wrong route name')
    }
    const { data: { results: crypkos, count } } = await request.send()
    const parsedCrypkos = crypkos.map(crypko => Crypko.parse(crypko))
    return {
      crypkos: parsedCrypkos,
      count,
    }
  }

  static getPlaceholders (num = SLOTS_NUM) {
    return _.times(num, () => Crypko.PLACEHOLDER)
  }

  static async fav (hash, fav = true) {
    const request = new APIRequest('/lists/fav/', {
      hash,
      fav,
    })
    const { status } = await request.send()
    if (status !== 200) {
      throw new Error('API Error')
    }
  }

  static async patch (hash, params) {
    const request = new APIRequest(`/crypkos/${hash}/`, params, 'patch')
    const { data } = await request.send()
    return Crypko.parse(data)
  }

  static async delete (hashes) {
    const request = new APIRequest('/crypkos/delete/', { hashes })
    const { status } = await request.send()
    if (status !== 204) {
      throw new Error('API Error')
    }
  }
}

export const localCrypkoPaginator = {
  _crypkos: LOCAL_CRYPKOS.map(Crypko.fromLocal),
  // * @param myParam
  getPage (page) {
    page = page ? parseInt(page) : 1
    return {
      crypkos: this._crypkos.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE),
      total: this._crypkos.length,
    }
  },
  getCarousel (crypkoHash) {
    const crypkoIdx = this._crypkos.findIndex(crypko => crypko.hash === crypkoHash)
    // TODO: add pagination
    return {
      crypkos: this._crypkos,
      idx: crypkoIdx,
    }
  },
}
