export default {
  general: {
    crypko: 'Crypko',
    model: 'Model',
    slot: 'Slots',
    stamina: 'CP',
    coin: 'Star Coins',
    guideline: 'Guideline',
    terms: 'Terms of use',
    'privacy-policy': 'Privacy policy',
    'crypko-guideline': 'Crypko Guideline',
    'commercial-license': 'Commercial License',
    dev: 'dev',
    support: {
      email: 'support@info.crypko.ai',
      'faq-link': 'https://crypko.zendesk.com/hc/en-us/categories/10851919592601-FAQ',
      'contact-link': 'https://crypko.zendesk.com/hc/en-us/requests/new',
    },
    label: {
      generate: 'Generate',
      fuse: 'Fuse',
      settings: 'Settings',
      set: 'Set',
      functionality: 'Functionality',
      save: 'Save',
      delete: 'Delete',
      update: 'Update',
      apply: 'Apply',
      select: 'Select',
      pleaseSelect: 'Please select',
      edit: 'Edit',
      yes: 'Yes',
      no: 'No',
      reset: 'Reset',
      close: 'Close',
      submit: 'Submit',
      download: 'Download',
      cancel: 'Cancel',
      confirm: 'Confirm',
      share: 'Share',
      report: 'Report',
      follow: 'Follow',
      following: 'Following',
      all: 'All',
      error: 'Error',
      language: 'Language',
      load: 'load',
      done: 'Done',
      more: 'More',
      refresh: 'Reload',
      none: 'None',
      purchase: 'Purchase',
      retry: 'Retry',
      full: 'Full',
      agree: 'Agree',
      send: 'Send',
      resend: 'Resend',
      'delete-unwanted': 'Organize Crypkos',
      account: 'Account',
      comment: 'Comment',
      'slots-remain': 'Slots remain',
      'contact-by-email': 'Contact by email',
      'email-address': 'Email',
      times: 'Times',
      fav: 'likes',
      notifications: 'Notifications',
      upgrade: 'Upgrade',
      support: 'Support',
      result: 'Result',
      owner: 'Owner',
      'try-now': 'Try Now',
      'acquire-now': 'Acquire Now',
      'view-other-crypkos': 'View Other Crypkos',
      'try': 'Try',
      'claim': 'Claim',
      'claim-all': 'Claim All',
      'claimed': 'Claimed',
      exchange: 'Exchange',
      details: 'Details',
      reward: 'Reward',
      feedback: 'Feedback',
      'continue-anyway': 'Continue Anyway',
      characters: 'Characters',
      recommended: 'Recommended',
      others: 'Others',
      'go-gen': 'Go Generate',
      feature: 'Feature',
    },
    settings: {
      AttrsCPConfirm: 'Confirm CP Consuming in Attribute Editing',
      NSFWConfirm: 'Show NSFW images',
    },
    msg: {
      succeeded: 'Succeeded',
      failed: 'Failed',
      retry: 'Please try again',
      saveConfirm: 'Do you want to save?',
      saveSeveralConfirm: 'Do you want to save {number} Crypkos?',
      saved: 'Saved.',
      unsaved: 'Unsaved progress exists.',
      overwrite: 'Do you want to overwrite?',
      delete: 'Do you want to delete?',
      fetching: 'Fetching...',
      placeholder: 'Please write something...',
      empty: 'Still empty.',
      error: 'Error occurred.',
      enableAfterSubscribe: 'Available in paid plan.',
      thankyou: 'Thank you very much!',
      waiting: 'Please wait.',
      welcome: 'Welcome to Crypko！',
      'cp-insufficient': 'CP is insufficient.',
      'coin-insufficient': 'Insufficient Star Coins',
      'before-share': 'When sharing to SNS, please follow {0}.',
      'cancel-crossfuse': 'Cancel Collab fusion?',
      'own-crypko': 'You own this Crypko.',
      'delete-licensed-crypko': 'Deleting a license purchased Crypko will disqualify it for commercial use.',
      'max-length': 'Max length is {0}.',
      'refresh-in': 'Refresh in {0}',
      'crypko-deleted': 'Crypko deleted.',
    },
  },
  models: {
    erica: 'Erica',
    freesia: 'Freesia',
    'freesia-m': 'FreesiaM',
    gerbera: 'Gerbera',
    'gerbera-m': 'GerberaM',
    hibiscus: 'Hibiscus',
    stylegan: 'StyleGAN',
    'edit-v1': 'EditV1',
    'canvas-v1': 'CanvasV1',
    others: 'Others',
    desc: {
      'hibiscus': 'upper body',
      'gerbera': 'full body',
      'gerbera-m': 'full body',
      'freesia': 'upper body',
      'freesia-m': 'upper body',
      'erica': 'face',
      'canvas-v1': 'face',
    },
    'supported-models': 'Supported Models',
    'supported-feature': 'Supported Feature',
    'gen-sample': 'Generation Sample',
    free: 'free model',
    paid: 'paid model',
  },
  crypko: {
    card: {
      tooltip: {
        chat: 'Chat',
        crossfuse: 'Collab Fuse',
        nsfw: 'NSFW',
      },
    },
    profile: {
      set_with_error: 'May include approval failure language',
      save: 'Save',
      generate: 'Generate',
      generate_title: 'Profile',
      save_confirm_desc: 'Enable Crypko chat using this profile?',
      save_and_chattable: 'Save & Enable chat',
      save_and_unchattable: 'Save & Disable chat',
      start_chat_title: 'Profile Saved',
      start_chat_desc: 'Chat is available now.',
      start_chat: 'Chat now',
      empty: 'No profile',
      edit: 'Click to add a profile.',
      choose_profile_title: 'Choose Profile',
      choose_profile_desc: 'The owner of this Crypko has updated its profile. Do you want to use the new profile?',
      use_new: 'Use the new profile',
      use_old: 'Using the old profile',
      generate_and_chat_title: 'Create Profile',
      generate_and_chat_desc: 'Create a profile so that you can chat with this Crypko.',
      input: 'Input Profile',
    },
    type: {
      GEN: 'Generate',
      FUSED: 'Fuse',
      ATTRS: 'Attributes',
      CANVAS: 'Canvas',
      VAR: 'Variation',
      KEYPOINT: 'Pose',
      PROJ: 'EnCrypko',
    },
    'relation-map': 'Relation Map',
    crossfuse: 'Collab Fuse',
    latest: 'Latest',
    owned: 'Owned',
  },
  auth: {
    'signup-signin': 'Sign Up / Sign In',
    signup: 'Sign Up',
    signin: 'Sign In',
    signout: 'Sign Out',
    username: 'Username',
    password: 'Password',
    'reset-pwd': 'Reset Password',
    'change-email': 'Update Email',
    'input-new-email': 'Update',
    'new-email-dialog-title': 'Change Email',
    'new-email-dialog-desc': 'Please input new email.',
    'new-email-label': 'New Email',
    'verify-pwd': 'Verify Password',
    'verify-pwd-desc': 'Please input your password to verify.',
    'email-sent-title': 'Email Sent',
    'email-sent-desc': 'Please go to check the verification email to complete the email address change operation.',
    'confirm': 'Confirm',
    'send-email': 'Send email',
    'send-reset-email': 'Reset by email',
    'new-pwd': 'New Password',
    'confirm-pwd': 'Confirm Password',
    'forget-pwd': 'Forget Password',
    'pwd-mismatch': 'Password Mismatch',
    'invalid-email': 'Invalid Email',
    'invalid-login': 'Invalid email or password. Email and password are case-sensitive.',
    'email-sent': 'Email has been sent. If you want to resend, please try again after %{second} seconds.',
    goCreate: 'Create Characters',
    goGallery: 'Visit Gallery',
    goPlayground: 'Chat with Mascot',
    goTravel: 'Go Travel',
    'email-verify-failed': 'Failed to verify email.',
    'please-login': 'Please sign in.',
    'official-twitter': 'Official Twitter',
    'official-discord': 'Official Discord',
    'age-confirm': 'I have reached the age stated in {0}.',
    'age-confirm-terms': 'Terms of Use Article 3',
    'agreements': 'I agree with {0} and {1}.',
    'agree-on-click': 'Clicking the "Sign Up" button implies the agreement on the followings:',
    'agree-on-guideline': 'I\'ve read {0} and agree with it.',
    'delete-account': 'Delete Account',
    'delete-account-confirm': 'OK, delete my account',
    'delete-account-title': 'Do you want to delete your account?',
    'delete-account-msg': 'When your account is deleted, your Crypkos, CP and slots are also deleted. Your account can\'t be recovered from deletion.',
    'delete-account-subscription-title': 'Active Subscription Warning',
    'delete-account-subscription-msg': 'You have to cancel your subscription before account deletion.',
    'continue-with-google': 'Continue with Google',
    'or': 'or',
    verifyEmail: {
      'title': 'Verify email',
      'desc': 'Your email needs to be verified before using this site. Please check your email inbox.',
      'done-browse': 'Account is verified.',
      'done-login': 'Account is verified. Please sign in.',
      'email-sent-address': 'Email: %{address}',
    },
  },
  dialog: {
    noSegmentation: {
      title: 'Load Error',
      text: 'Failed to load contents.<br/>Please reload and try again.',
      actions: {
        true: 'OK',
      },
    },
    nsfw: {
      text: 'This image may contain sensitive content.',
      actions: {
        true: 'Display',
        false: 'Don\'t display',
      },
    },
    'confirm-terms': {
      title: 'Notice of Revision of Terms of Use',
      text: 'From {0}/{1}/{2}, the Terms of Service is revised. The following revised Terms of Service will apply to the Service.',
      actions: {
        true: 'Agree',
        false: 'Disagree',
      },
    },
    'freemium-fuse': {
      text: 'Upgrade your plan to fuse two owned Crypkos.',
    },
    'chat-cp': {
      text: 'Sending a message consumes 3CP.\n* This dialog will only display once.',
    },
    'chat-freemium': {
      title: 'Chat Feature',
      actions: {
        0: 'Chat with Mascot',
      },
    },
    'get-coin': '{delta} Stars! Total: {total}.',
    'spend-coin': 'Spent {delta} Stars. Total: {total}.',
    'coin-exchanged': {
      title: 'NotAvailable',
    },
    'not-enough-coin': {
      title: 'Not enough stars',
      message: 'Not enough stars. Complete daily tasks to obtain stars.',
    },
    'remove-album': {
      title: 'Confirm',
      message: 'Are you sure to remove this travel image from Crypko?',
      message2: 'Are you sure to remove this travel image from Crypko? This action may be irreversible.',
    },
    'earn-coins': {
      title: 'Earn coins',
      'daily-task': 'Complete daily tasks.',
      travel: 'Play Crypko Travel.',
    },
    'require-crypko': {
      title: '需要Crypko',
      message: '在生成功能中生成Crpyko吗？',
    },
    'generate-crypko': {
      title: 'Generate Crypko',
      'save-and-continue': 'Save and Continue',
      'msg-travel': 'You need to own a Hibiscus/Freesia/Gerbera model Crypko to start travel.',
    },
    'survey-no-travel': {
      title: '500 Free Coins',
      message: 'Complete a user survey to earn 500 free coins. You need to play Crypko Travel at least once.',
      action: 'Try Travel',
    },
  },
  header: {
    router: {
      page404: '404',
      about: 'About',
      'create-attributes': 'Attributes',
      'create': 'Create',
      'create-canvas': 'Canvas',
      emoteDemo: 'E-mote',
      'create-fuse': 'Fuse',
      'create-generate': 'Generate',
      'create-keypoint': 'Pose',
      'create-marking': 'Marking',
      'create-variation': 'Variation',
      'create-interpolate': 'Video',
      'workbench': 'Editor',
      'user-home': 'Home',
      'user-crypko': 'Crypkos',
      'user-album': 'Travel',
      'user-faved': 'Favorites',
      'notifications': 'Notifications',
      'user-list': 'Lists',
      'followings-user': 'Following',
      'followers-user': 'Followers',
      signin: 'Login',
      list: 'Lists',
      logout: 'Log Out',
      gallery: 'Gallery',
      'signup-signin': 'SignUp / SignIn',
      'signup': 'Sign Up',
      'signup-welcome': 'Sign Up',
      'introduction': 'Crypko - AI Anime Character Generation',
      help: 'Help',
      settings: 'Settings',
      'terms': 'Terms of service',
      'privacy-policy': 'Privacy policy',
      'guideline': 'Guideline',
      'verify-email': 'Verify Email',
      'verify-password': 'Verify Password',
      'reset-pwd-request': 'Reset Password',
      'reset-pwd-submit': 'Reset Password',
      plans: 'Plans',
      'gallery-crypko': 'Gallery',
      'gallery-trend': 'Trend',
      'gallery-chattable': 'Chat',
      'gallery-crossfuse': 'Collab Fuse',
      'gallery-following': 'Followings',
      'gallery-gerbera': 'Gerbera',
      'gallery-freesia': 'Freesia',
      'gallery-freesia_m': 'FreesiaM',
      'gallery-erica': 'Erica',
      'gallery-search': 'Search',
      'gallery-travel': 'Gallery',
      'crypko-tree': 'Relation Map',
      travel: 'Travel',
      play: 'Play',
      survey: 'Survey',
    },
    avatar: {
      home: 'Home',
      notifications: 'Notifications',
      stampCard: 'Stamp Card',
      plans: 'Store',
      settings: 'Settings',
      help: 'Help',
      logout: 'Log Out',
      'system-notification': 'Updates',
      'daily-task': 'Daily Task',
    },
  },
  gallery: {
    views: {
      home: 'Home',
      album: 'Travel',
      crypkos: 'Crypkos',
      chattable: 'Chat',
      lists: 'Lists',
      users: 'Users',
      trend: 'Trend',
      crossfuse: 'Collab Fuse',
      following: 'Followings',
      hibiscus: 'Hibiscus Latest',
      gerbera: 'Gerbera(M) Latest',
      freesia: 'Freesia(M) Latest',
      erica: 'Erica Latest',
      canvas: 'Canvas Latest',
      rank: 'Ranking',
    },
    filter: {
      label: 'Filter',
      options: 'options',
      model: 'Model',
      type: 'Type',
      status: 'Status',
    },
    ordering: {
      'title': 'Sort By',
      'created': 'Oldest',
      '-created': 'Latest',
      'name': 'Name Ascending',
      '-name': 'Name Descending',
      '-likes': 'Likes',
      'default': 'Recommanded',
      'date_joined': 'Oldest',
      '-date_joined': 'Latest',
      'username': 'Name Ascending',
      '-username': 'Name Descending',
      'added': 'Oldest added',
      '-added': 'Latest added',
      'likes': 'Most liked',
      'claimed_at': 'Latest',
    },
  },
  user: {
    status: {
      'please-login': 'Please login or signup',
      empty: 'Still empty.',
    },
  },
  privacy: {
    setting: 'Privacy Settings',
    public: {
      title: 'Public',
      description: 'All users can view this crypko.',
      save: 'Save (Public)',
    },
    private: {
      title: 'Private',
      description: 'Only you can view this crypko. You can\'t share private crypkos.',
      save: 'Save (Private)',
    },
  },
  avatar: {
    avatar: 'avatar',
    select: 'Set as avatar',
  },
  stamp: {
    loginBonus: 'Login Bonus',
    'next-stamp-in': 'Next stamp in {0}',
    preparing: 'We are preparing...',
    obtain: 'Obtain by daily login',
  },
  detail: {
    profile: {
      title: 'Profile',
      default: 'Profile of Crypko {name}',
    },
    hash: {
      title: 'Hash',
    },
    created: {
      title: 'Created at',
    },
    model: {
      title: 'Model',
      tooltip: 'The model that generated the Crypko',
    },
    type: {
      title: 'Type',
      tooltip: 'The type of the Crypko',
    },
    rating: {
      title: 'Rating',
      empty: 'なし',
    },
    comment: {
      title: 'Comment',
      edit: 'Edit',
      empty: '',
    },
    parentCrypko: {
      title: 'Origins',
      empty: 'N/A',
    },
    childCrypko: {
      title: 'Derivatives',
      empty: 'N/A',
    },
    tags: {
      title: 'Tags',
      empty: 'N/A',
      rate: 'Rate',
    },
    name: {
      edit: 'Edit name',
    },
    toolbar: {
      'favorite': 'Like',
      'share': 'Share',
      'addList': 'Add to lists',
      'edit': 'Edit',
      'download': 'Download',
    },
  },
  edit: {
    composition: 'composition',
    layer: 'layer',
    style: 'style',
    layers: {
      'background': 'Background',
      'backHair': 'Back hair',
      'skin': 'Skin',
      'face': 'Face',
      'frontHair': 'Front hair',
      'clothes': 'Cloth',
      'eyeWhite': 'White of the eye ',
      'eyeBlack': 'Iris',
      'eyelashes': 'Eyelash',
      'nose': 'Nose',
      'mouth': 'Mouth',
      'eyebrows': 'Eyebrow',
    },
    settings: {
      mode: {
        drawing: 'Brush',
        eraser: 'Eraser',
        selection: 'Selection',
        rectangleCutout: 'Rectangle Cutout',
        freeCutout: 'Free Cutout',
        colorBucket: 'Color Bucket',
      },
      cursorSize: 'Cursor Size',
      mixRatio: 'Mix Ratio',
    },
  },
  footer: {
    'contact': {
      'title': 'Contact us',
      'text-1': 'Help on functionalities can be referred at {0} page.',
      'text-2': 'Contact {0} for other questions.',
      'text-3': '※Crypko is still under development. It could take some time for us to get back to you. Thanks for your understanding.',
      'text-4': '※You can also join {0} community for help.',
    },
  },
  help: {
    usage: 'Usage',
    create: {
      generate: {
        desc: 'Generate characters in one click',
        1: '1. Select the generation model and then click generate.',
        2: '2. Choose the results you like and save.',
      },
      fuse: {
        desc: 'Fuse two characters to create a new one.',
        1: '1. Select two Crypkos from the same model.',
        0: '* To fuse two Crypkos you owned, a paid plan is needed.',
        2: '2. Choose the results you like and save.',
      },
      crossfuse: {
        desc: 'Collab fusion lets you fuse your Crypko with the Crypko from other users. While collab fusion costs 30CP, The provider will receive 10CP as reward (maximum once per day).',
        2: 'How to set',
        3: 'You can set your collab fuse Crypkos in your user home page.',
        4: 'How to collab fuse',
        5: 'Select other users\' collab fuse Crypko from fuse selection dialog.',
        6: 'Select your own Crypko and fuse.',
        7: 'You can also access collab fusion by clicking the cross fusion button',
      },
      attributes: {
        desc: 'Change the attributes of the character.',
        1: '1. Select the Crypko you want to change attribute.',
        2: '2. Change attributes through the sliders and click change.',
        3: '3. Save the result if you like.',
      },
      variation: {
        desc: 'Generate character variations with different poses, clothes, etc.',
        1: '1. Select the Crypko you want to generate variations.',
        2: '2. Choose variation mode and strength.',
        3: '3. Click the Variation button.',
        4: '4. Save the result if you like.',
      },
      canvas: {
        desc: 'Edit characters freely on the canvas.',
        0: '*Applying edits does not consume CP.',
        '01': '*Saving consumes 20 CP.',
        '02': '*The above specifications are subject to change at the end of the beta test of Canvas function. We appreciate your understanding.',
        1: '1. Select the Crypko (Erica or CanvasV1 model only) that will be the base composition.',
        2: '2. Edit the canvas using the tools.',
        3: '3. (Optional) Style reference image can be specified for another Crypko.',
        4: '4. (Optional) Right mouse click on a layer can adjust the mix ratio between the composition image and the style reference image.',
        5: '5. Apply the edit with the Apply button and save when you like the result.',
      },
      keypoint: {
        0: '*まだ開発中です。',
        1: '1. ポーズを変更したいCrypkoを選択する。',
        2: '2. 参考ポーズのCrypkoを選択する。',
        3: '3. 変更ボタンをクリックする。',
        4: '4. 結果が気に入ったら保存する。',
      },
      interpolate: {
        desc: 'Create transformation videos of two Crypkos.',
        0: '*Videos created by this feature cannot be used for commercial purposes.',
        1: '*It may take some time for the video to be generated.',
        2: '*The above specifications are subject to change at the end of the beta test. We appreciate your understanding.',
        3: '1. Select the Crypkos you want to generate tranformation videos.',
        4: '2. Click the generate button.',
        5: '3. Download the generated video.',
      },
      travel: {
        0: 'Crypko Travel allows you to send your Crypko on trips to different worlds. You can obtain travel photos and star coins as rewards.',
        2: '* Travel is a Beta feature, all specifications are subject to change.',
        3: 'Regarding travel images',
        4: '1. Each travel photo consumes an album slot of the Crypko.',
        5: '2. Travel photos are associated with the Crypko character. If the Crypko is deleted, its travel photos will also be removed.',
        6: '3. The privacy setting of travel images (public or private) follows the Crypko\'s privacy setting.',
        7: '4. After saving a travel image, its license will align the license of the Crypko.',
      },
    },
    manage: {
      title: 'Manage Crypko',
      detail: 'Under development.',
    },
    more: {
      title: 'Others',
      detail: 'Under development.',
    },
  },
  list: {
    create: 'Create list',
    creator: 'Creator',
    delete: 'Delete the list',
    edit: 'Edit the list',
    settings: 'List Settings',
    saveToList: 'Add to list',
    placeholder: 'name',
    downloadConfirmTitle: 'Download as a zip file.',
    downloadConfirmText: 'Download all crypkos from the list.',
    deleteConfirmTitle: 'Do you want to delete?',
    deleteConfirmText: 'Failed to delete the crypko from the list.',
    pleaseLogin: 'Please login.',
    empty: 'Still empty.',
    selectFromCrypkos: 'From Crypkos owned',
    selectFromLists: 'From lists(Owned)',
    selectFromCrossfuse: 'From Collab fuse',
  },
  userhome: {
    profile: 'Profile',
    pickUp: 'Pickups',
    introduction: 'Introduction',
  },
  page404: {
    subtitle: 'Seems that you are lost.',
    desc:
      'The page you are looking for is not available. We apologize for the inconvenience.',
    btn: 'Back to last page',
  },
  workbench: {
    'under-dev': 'Currently under development.',
    select: {
      'screen-too-small': 'A larger screen size is needed in order to use this feature.',
      'canvas-feature': 'Canvas feature',
      'create-character': 'Create Characters',
      'create-illust': 'Create Media',
    },
    generate: {
      'select-model': 'Select Model',
      normalizePose: 'Generate in standard pose',
      normalizedPose: 'Standard pose',
    },
    fuse: {
      'select': 'Select your Crypko',
      'fuseResult': 'Results',
      sameCrypko: 'The same Crypkos cannot be fused.',
      notSameModel: 'Please select the same model.',
      notCorrectModel: 'Crypkos that cannot be fused are selected.',
      bothOthers: 'Please select at least one Crypko owned by yourself.',
    },
    editMode: {
      'selection': 'Selection',
      'rectangleCutout': 'Rectangle Cut',
      'freeCutout': 'Free Cut',
      'drawing': 'Brush',
      'eraser': 'Eraser',
      'colorBucket': 'Color Bucket',
      'undo': 'Undo',
      'redo': 'Redo',
    },
    keypoint: {
      'select': 'Select your Crypko',
      'load': 'Load from another crypko',
      'toggle': 'Toggle Points',
      'choose-part': 'Select parts',
    },
    variation: {
      pose: 'Pose',
      hand: 'Hand',
      hair: 'Hair',
      face: 'Face',
      clothes: 'Clothes',
      strength: 'Strength',
      scale: 'Scope',
      mode: 'Mode',
      result: 'Results',
    },
    interpolate: {
      notCorrectModel: 'Please select either Freesia or FreesiaM model Crypkos.',
      sameCrypko: 'Video for the same Crypko is not supported.',
      notSameModel: 'Please select Crypkos with the same model.',
      'remove-buffer': 'Exclude buffer frames',
      'tweet-confirm': 'Please attach the downloaded mp4 file to your tweet.',
    },
  },
  attrs: {
    erica: {
      'HairColors': 'Hair Colors',
      'EyeColors': 'Eye Colors',
      'Others': 'Other Attributes',
      'blonde_hair': 'blonde hair',
      'brown_hair': 'brown hair',
      'black_hair': 'black hair',
      'blue_hair': 'blue hair',
      'pink_hair': 'pink hair',
      'purple_hair': 'purple hair',
      'green_hair': 'green hair',
      'red_hair': 'red',
      'silver_hair': 'silver hair',
      'white_hair': 'white hair',
      'orange_hair': 'orange hair',
      'aqua_hair': 'aqua hair',
      'grey_hair': 'grey hair',
      'blue_eyes': 'blue eyes',
      'red_eyes': 'red eyes',
      'brown_eyes': 'brown eyes',
      'green_eyes': 'green eyes',
      'purple_eyes': 'purple eyes',
      'yellow_eyes': 'yellow eyes',
      'pink_eyes': 'pink eyes',
      'aqua_eyes': 'aqua eyes',
      'black_eyes': 'black eyes',
      'orange_eyes': 'animal ears',
      'long_hair': 'drill',
      'short_hair': 'short',
      'twintails': 'long',
      'twin_tail': 'yellow',
      'drill_hair': 'grey',
      'ponytail': 'aqua',
      'dark_skin': 'orange',
      'blush': 'white',
      'smile': 'silver',
      'open_mouth': 'red',
      'hat': 'green',
      'ribbon': 'purple',
      'glasses': 'pink',
      'animal_ears': 'orange eyes',
    },
    freesia: {
      'Attributes': 'Attributes',
      'Expressions': 'Expressions',
      'HairStyles': 'Hair Styles',
      'HairColors': 'Hair Colors',
      'EyeColors': 'Eye Colors',
      'Dev': 'Dev',
      ':d': ':D',
      '^_^': '^ ^',
      ';)': ';)',
      ';d': ';D',
      ':<': ':<',
      ':o': ':o',
      '=_=': '¯\\_(ーワー)_/¯',
      'ahoge': 'ahoge',
      'angry': 'angry',
      'animal_ears': 'animal ears',
      'aqua_eyes': 'aqua eyes',
      'aqua_hair': 'aqua hair',
      'bare_shoulders': 'bare shoulders',
      'black_dress': 'black dress',
      'black_eyes': 'black eyes',
      'black_hair': 'black hair',
      'blazer': 'blazer',
      'blonde_hair': 'blonde hair',
      'blue_dress': 'blue dress',
      'blue_eyes': 'blue eyes',
      'blue_hair': 'blue hair',
      'blush': 'blush',
      'bob_cut': 'bob cut',
      'breasts': 'breasts',
      'brown_eyes': 'brown eyes',
      'brown_hair': 'brown hair',
      'casual': 'casual',
      'chibi': 'chibi',
      'child': 'child',
      'closed_eyes': 'closed eyes',
      'closed_mouth': 'closed mouth',
      'dark_skin': 'dark skin',
      'drill_hair': 'drill hair',
      'dutch_angle': 'dutch angle',
      'embarrassed': 'embarrassed',
      'green_eyes': 'green eyes',
      'green_hair': 'green hair',
      'grey_eyes': 'grey eyes',
      'grey_hair': 'grey hair',
      'hair_over_one_eye': 'hair over one eye',
      'half-closed_eyes': 'half-closed eyes',
      'happy': 'happy',
      'jitome': 'jitome',
      'light_smile': 'light smile',
      'loli': 'loli',
      'long_hair': 'long hair',
      'messy_hair': 'messy hair',
      'naughty_face': 'naughty face',
      'one_eye_closed': 'one eye closed',
      'open_mouth': 'open mouth',
      'orange_eyes': 'orange eyes',
      'orange_hair': 'orange hair',
      'parted_bangs': 'parted bangs',
      'pink_eyes': 'pink eyes',
      'pink_hair': 'pink hair',
      'pointy_ears': 'pointy ears',
      'ponytail': 'ponytail',
      'purple_eyes': 'purple eyes',
      'purple_hair': 'purple hair',
      'red_eyes': 'red eyes',
      'red_hair': 'red hair',
      'sharp_teeth': 'sharp teeth',
      'short_hair': 'short hair',
      'short_twintails': 'short twintails',
      'silver_hair': 'silver hair',
      'smile': 'smile',
      'surprised': 'surprised',
      'tareme': 'drooping eyes',
      'tsurime': 'slanted eyes',
      'twintails': 'twintails',
      'two_side_up': 'two side up',
      'very_long_hair': 'very long hair',
      'wavy_hair': 'wavy hair',
      'white_hair': 'white hair',
      'white_shirt': 'white shirt',
      'yellow_eyes': 'yellow eyes',
      'younger': 'younger',
      'under-rim_eyewear': 'glasses',
      'side_ponytail_left': 'side ponytail (left)',
      'side_ponytail_right': 'side ponytail (right)',
      'high_ponytail': 'high ponytail',
      'low_ponytail': 'low ponytail',
      'short_ponytail': 'short ponytail',
      'expressionless': 'expressionless',
      'crying_with_eyes_open': 'sobbing',
      'kimono': 'kimono',
      'frown': 'frown',
      'swimsuit': 'swimsuit',
    },
    'freesia-m': {
      'Attributes': 'Attributes',
      'Expressions': 'Expressions',
      'HairStyles': 'Hair Styles',
      'HairColors': 'Hair Colors',
      'EyeColors': 'Eye Colors',
      'Dev': 'Dev',
      ':d': ':D',
      '^_^': '^ ^',
      ';)': ';)',
      ';d': ';D',
      ':<': ':<',
      ':o': ':o',
      '=_=': '¯\\_(ーワー)_/¯',
      'ahoge': 'ahoge',
      'angry': 'angry',
      'animal_ears': 'animal ears',
      'aqua_eyes': 'aqua eyes',
      'aqua_hair': 'aqua hair',
      'bare_shoulders': 'bare shoulders',
      'black_dress': 'black dress',
      'black_eyes': 'black eyes',
      'black_hair': 'black hair',
      'blazer': 'blazer',
      'blonde_hair': 'blonde hair',
      'blue_dress': 'blue dress',
      'blue_eyes': 'blue eyes',
      'blue_hair': 'blue hair',
      'blush': 'blush',
      'bob_cut': 'bob cut',
      'breasts': 'breasts',
      'brown_eyes': 'brown eyes',
      'brown_hair': 'brown hair',
      'casual': 'casual',
      'chibi': 'chibi',
      'child': 'child',
      'closed_eyes': 'closed eyes',
      'closed_mouth': 'closed mouth',
      'dark_skin': 'dark skin',
      'drill_hair': 'drill hair',
      'dutch_angle': 'dutch angle',
      'embarrassed': 'embarrassed',
      'green_eyes': 'green eyes',
      'green_hair': 'green hair',
      'grey_eyes': 'grey eyes',
      'grey_hair': 'grey hair',
      'hair_over_one_eye': 'hair over one eye',
      'half-closed_eyes': 'half-closed eyes',
      'happy': 'happy',
      'jitome': 'jitome',
      'light_smile': 'light smile',
      'loli': 'loli',
      'long_hair': 'long hair',
      'messy_hair': 'messy hair',
      'naughty_face': 'naughty face',
      'one_eye_closed': 'one eye closed',
      'open_mouth': 'open mouth',
      'orange_eyes': 'orange eyes',
      'orange_hair': 'orange hair',
      'parted_bangs': 'parted bangs',
      'pink_eyes': 'pink eyes',
      'pink_hair': 'pink hair',
      'pointy_ears': 'pointy ears',
      'ponytail': 'ponytail',
      'purple_eyes': 'purple eyes',
      'purple_hair': 'purple hair',
      'red_eyes': 'red eyes',
      'red_hair': 'red hair',
      'sharp_teeth': 'sharp teeth',
      'short_hair': 'short hair',
      'short_twintails': 'short twintails',
      'silver_hair': 'silver hair',
      'smile': 'smile',
      'surprised': 'surprised',
      'tareme': 'drooping eyes',
      'tsurime': 'slanted eyes',
      'twintails': 'twintails',
      'two_side_up': 'two side up',
      'very_long_hair': 'very long hair',
      'wavy_hair': 'wavy hair',
      'white_hair': 'white hair',
      'white_shirt': 'white shirt',
      'yellow_eyes': 'yellow eyes',
      'younger': 'younger',
      'under-rim_eyewear': 'glasses',
      'side_ponytail': 'side ponytail',
      'expressionless': 'expressionless',
      'crying_with_eyes_open': 'Sobbing',
      'kimono': 'kimono',
      'frown': 'frown',
      'swimsuit': 'swimsuit',
    },
  },
  payment: {
    catalog: {
      cp: 'CP',
      features: 'Features',
      model: 'Models',
    },
    unit: {
      recovery: '{0} min/CP',
      limit: '{0} CP',
      slot: '{0}',
    },
    plans: {
      Freemium: 'Freemium',
      Starter: 'Starter',
      Standard: 'Standard',
      Premium: 'Premium',
    },
    'slot-num': '{0} Slots',
    'license-num': '{0} Image',
    plan: 'Plan',
    trial: 'Trial',
    '7-day-trial': '7-day trial',
    'in-trial': 'Trial',
    'with-trial': 'Continue with Trial',
    'without-trial': 'Continue without Trial',
    'trial-desc': 'You are eligible for a 7-day trial!\n\n*If your card has already been used for purchasing a trial, your subscription will be canceled.',
    invoice: 'Invoice',
    unsubscribed: 'Unsubscribed',
    membership: 'Membership',
    'upgrade-plan': 'Upgrade plan',
    monthly: 'Monthly',
    'specified-commercial-transactions': 'Notations pursuant to the Act on Specified Commercial Transactions',
    'plan-features': {
      'cp-recovery': 'CP Recovery',
      'cp-limit': 'CP Recovery Limit',
      slots: 'Slots',
      gen: 'Generate',
      fuse: 'Fuse',
      attrs: 'Attributes',
      variation: 'Variation',
      canvas: 'Canvas',
      video: 'Video',
      'private-save': 'Private save',
      download: 'Download without watermark',
      commercial: 'Commercial use',
      chat: 'Chat',
      travel: 'Travel',
    },
    'ip-country-not-support-billing': 'Please note currently we are only supporting payments from partial Asian countries. We are working on international payment support!',
    'jpy': 'JPY ¥{0}',
    'jpy-excluded-tax': 'JPY ¥{0} excluded tax',
    'current-plan': 'Current Plan',
    'freemium-crypko-model': 'You need to upgrade plan to use the same model as this Crypko.',
    'model-plan-restriction': 'You need to upgrade plan to use this model.',
    'feature-plan-unavailable': 'This feature is not available for your current plan level.',
    'view-plans': 'View Plans',
    'tax-included': '(tax included)',
    'tax-excluded': '(tax excluded)',
    'plan-ends': 'Plan Ends',
    'next-payment': 'Next Payment',
    'thank-you': 'Thank you for your purchase.',
    'manage-subscription': 'Manage Subscription',
    'campaign': 'For sale',
    'first-month-only': 'First Month',
    'onetime': 'Onetime',
    'recurring': 'Recurring',
    '30-days': '30 Days',
    'discount': 'Discount',
    'paid-feature': 'Paid plan feature',
    'plan-upgrade-msg': 'Do you want to upgrade your plan?\nYou will be charged prorated amounts for the time spent on the current plan.',
    'purchase-license': 'Purchase License',
    'purchased-license': 'License Purchased',
    'purchase-license-msg': 'Please purchase the license at Crypko page.',
    'purchased': 'Purchased',
    'pay-by-email': 'The invoice will be sent by email. Please follow the steps in the email and complete the payment.',
    'pay-by-checkout': 'You will be redirected to the purchase page. Invoice will not be issued if you choose this option.',
    'check-email-invoice': 'Invoice has been sent to your email {0}\nPlease follow the steps in the email and complete the payment.',
    'invoice-paid': 'Invoice Paid',
    'coin-exchange-msg': 'Do you want to exchange?',
    notice: {
      'tax-included-price': '*Tax included price in JPY.',
      'tax-excluded-price': '*Tax excluded price in JPY.',
      'payment-countries': '*We support payment from Japan, US, Mainland China, Taiwan, HongKong, Macao, Korea, Australia, Singapore, New Zealand and 27 EU countries. Please make sure your payment billing address is in the above.',
      'country-tax-rate': '*Users residing outside Japan will be charged the tax rate applicable in the country of residence.',
      'item-purchase-desc': '*Each item may be purchased when enrolled in any plan. A Commercial License may be purchased for each Crypko image stored in the slot when enrolled in any plan. Crypko images for which a Commercial License has been purchased can be stored privately, downloaded without a watermark, and used commercially when enrolled in any plan and while stored in the User’s slot. Please see the Terms of Use for Crypko, Crypko Guidelines, and the FAQ section for details.',
      'plan-cp-desc': '*When newly enrolling in a plan, the CP balance will be set to the CP Recovery Limit for each plan. However, when upgrading from the Standard Plan to the Premium Plan, the CP balance will be carried over unchanged.',
      'cp-recover-desc': '*For each plan, when the CP balance reaches the CP Recovery Limit set for each plan, it will no longer recover with the passage of time.',
      'cp-6times-desc1': '*The upper limit number of the possessed CP for the Freemium plan is the CP Recovery Limit. The upper limit number of the possessed CP for the Standard Plan and Premium Plan is six times the CP of the CP Recovery Limit set for each plan.',
      'cp-6times-desc2': '*When renewing for the month the Standard Plan or Premium Plan recurringly purchased at a fixed monthly rate, in addition to the CP balance at the time of renewal, the CP of the CP Recovery Limit will be added to the CP balance. However, this will not apply when the CP balance will exceed the upper limit number of the possessed CP. This substantially makes the CP usage period six months from the date of granting.',
      'plan-change-desc': '*When cancelling the Standard Plan or Premium Plan recurringly purchased at a fixed monthly rate, the plan will be automatically converted to a Freemium plan upon expiration of the valid contract period. The Standard Plan or Premium Plan one time purchased will be automatically converted to a Freemium plan upon expiration of the valid contract period. Even after conversion to a Freemium plan, it is still possible to purchase a Standard Plan or Premium Plan at a fixed monthly rate or under a single-use contract.',
      'canvas-fuse-desc': '*Crypko images created using the CANVAS function will not be usable for the FUSE or ATTRS feature.',
      'more': '*Please refer to {0}, {1} and {2} for details.',
    },
  },
  notifications: {
    none: 'No notification.',
    title: {
      info: 'Info',
      fav: 'Liked',
      follow: 'New follower',
      fill: 'Received CP',
      slot: 'Received Slot',
      crossfuse: 'Collab fusion',
      crossfuse_result: 'Collab fusion',
      fav_album: 'Liked',
    },
    content: {
      fav: '{0} liked your Crypko {1}.',
      follow: '{0} followed you.',
      fill: 'System presented {0} CP.',
      slot: 'System presented {0} Slot(s).',
      crossfuse: '{0} used your Crypko {1} for collab fusion.',
      'crossfuse-reward': ' {0} is rewarded.',
      'crossfuse_result': 'Check {0}\'s collab fusion results of your Crypko {1}: {2}.',
      fav_album: '{0} liked your travel image {1}.',
    },
    'read-all': 'Read All',
  },
  search: {
    'no-data': 'No relevant Tag found, will search by name.',
    'search-by-hash': 'Search by Hash...',
    'hint': 'Hash, name, tags...',
  },
  link: {
    'privacy-policy': 'https://www.preferred.jp/en/policy/',
  },
  changelogs: {
    230614: {
      img: '/images/updates/230614/banner-en.jpg',
      'vid-url': 'https://www.youtube.com/watch?v=YFmUZitWVAQ',
      title: 'Crypko Chat',
      desc: 'Crypko Chat is now Alpha released. Please refer to the video for details.',
    },
    230712: {
      img: '/images/updates/230712/banner.jpg',
      title: 'Gerbera(M) Pose Variation',
      desc: 'You can now generate pose variants for Gerbera(M) model Crypkos.\n*Paid feature',
    },
    230720: {
      title: '【Ended】Swimsuit Attribute',
      desc: 'Freesia(M)\'s swimsuit attribute adjust is available for a limited time until the end of August. Try dressing your favorite Crypko in a swimsuit!\n*Paid feature',
    },
    230810: {
      img: '/images/updates/230810/banner-en.jpg',
      title: '【Ended】Mascot(swimsuit) exchange campaign',
      desc: 'Redeem the mascot swimsuit version for a total of 1000CP during the limited period.! The campaign is open to all users. Try chatting with the Crypko mascot dressed in a swimsuit!\n',
    },
    230930: {
      img: '/images/updates/230930/banner.jpg',
      title: 'New attribute',
      desc: 'New adjustable attribute ¯\\_(ーワー)_/¯ is available！\n*Paid feature',
    },
    231020: {
      img: '/images/updates/231020/banner.jpg',
      title: 'Ranking',
      desc: 'User ranking is now available in Gallery🔥',
    },
    231027: {
      img: '/images/updates/231027/banner.jpg',
      title: 'Tag Rarity',
      desc: 'Tag rarity is now displayed in Generate for Freesia model.\n*Tag rarity calculation has been adjusted.',
    },
    240116: {
      img: '/images/updates/240116/banner-en.jpg',
      title: 'Daily task and stars',
      desc: '{0}Star system has been added, which can be exchanged for CP or slots. Complete daily tasks to earn {0}stars!',
    },
    240321: {
      img: '/images/help/travel.jpg',
      title: 'Crypko Travel',
      desc: 'The new feature Travel is now open for beta test! Send your character to different worlds to collect character travel photos!',
    },
    240520: {
      img: '/images/updates/240520/orbis.jpg',
      title: 'New travel world',
      desc: 'New travel world Orbis has been added! Let\'s travel to a parallel world similar to the real world!',
    },
    240525: {
      img: '/images/updates/240525/leg.jpg',
      img2: '/images/updates/240525/gerbera.jpg',
      title: 'Travel photo generation problems fixed',
      desc: 'Travel photo generation problems fixed.\n1. The problem of the character\'s legs often being buried in water or soil.',
      desc2: '2. The resolution problem of Gerbera model travel photos.',
    },
    240708: {
      img: '/images/updates/240708/banner.jpg',
      title: 'Character message in Travel feature',
      desc: 'A feature has been added to the travel function that generates travel photos with messages at a certain probability!\n*Users with a paid plan can control this feature.',
    },
    240726: {
      title: 'New travel world',
      desc: 'New travel world Cybritus has been added! Explore a futuristic urban world dominated by cybernetics!\n*Paid feature',
    },
    240823: {
      title: 'New model Hibiscus',
      desc: 'New model Hibiscus has been added! Generate more Crypkos with the new model!',
    },
  },
  rank: {
    fav_rank_week: 'Like (weekly)',
    crossfuse_rank_week: 'Collab Fuse (weekly)',
    fav_rank_all: 'Like (all-time)',
    follower_rank: 'Follower',
  },
  dailyTask: {
    title: 'Daily Task',
    action: {
      fav: 'Give a like {0} time',
      gen: 'Perform Generate {0} time',
      fuse: 'Perform Fuse {0} time',
      save: 'Perform Save {0} time',
      crossfuse_set: 'Set Crypko in Collab Fuse',
      travel: 'Perform Travel {0} time',
    },
  },
  play: {
    travel: {
      title: 'Travel',
      desc: '✈️ Travel to different places to collect travel photos.',
    },
    mascot: {
      title: 'Mascot',
      desc: '💬 Chat and interact with the Crypko Mascot.',
    },
  },
  introduction: {
    travel: {
      desc: 'Send your unique character on journeys to different worlds and collect one-of-a-kind travel photos!',
      contact: '*The feature to generate illustrations with backgrounds for arbitrary character image is available for business use. For more information, please contact us.',
    },
    'release': {
      'title': 'Crypko Official Release',
      'start': 'Anime character generation platform Crypko™',
      'start-2': 'v1.0 official release',
      'notice-on-terms': '※Crypko has editing functions that allows the user to edit the hair, face, clothes, style, etc. of the generated character. Please comply with the {0}.',
      'terms-link-text': 'Terms of Service',
      'signup': 'Start making characters',
    },
    'generation': {
      'title': 'Character Generation',
      'detail': 'Crypko™, powered by GAN (generative adversarial network), creates waist up illustrations of anime characters',
      'text': 'Having learned features from data, it can journey freely in the space of character illustrations with coherent transformation.',
    },
    'memes': {
      'title': 'Smartphone App MEMES',
      'detail': 'The smartphone application MEMES, where Crypko serves as the core technology behind, is now available in Apple App Store & Google Play Store.',
      'access': 'MEMES Homepage',
    },
    'chara-design': {
      'title': 'Character Design',
      'detail': 'Our model not only generates anime characters automatically, but also reflects your taste and intention.',
      'detail2': 'It realizes your intuition into characters in a coherent and consistent way.',
    },
    'animation': {
      'title': 'Further Possibility of Creation',
      'detail': 'Furthermore, we can add smooth animation or edit the characters without drawing skills.',
    },
  },
  travel: {
    'crypko-travel': 'Crypko Travel',
    actions: 'Actions',
    photos: 'Travel Photos',
    'go-travel': 'Go Travel',
    'obtain-photos': 'Obtain photos in Travel',
    'claim-with-save': 'Confirm & Save to Crypko',
    'save-album': 'Save to Crypko',
    saved: 'Saved',
    worlds: 'Worlds',
    'worlds-info': {
      Arcadia: 'A nature-rich fantasy world',
      Orbis: 'A parallel world similar to the real world',
      Cybritus: 'A futuristic urban world dominated by cybernetics.',
    },
    history: 'History',
    'history-display': 'Only displaying history in one month.',
    state: {
      traveling: 'Traveling',
      generating: 'Traveling',
      unclaimed: 'Result Confirm',
      failed: 'Failed',
    },
    'generating-msg': 'Travel photo is generating. Usually it takes less than 20 seconds.',
    'failed-msg': 'Travel photo generation failed. {0} CP will be returned.',
    'has-travel-msg': 'Start a new travel after the current one\'s result is confirmed.',
    'start-travel-msg': 'Start travel by selecting a world.',
    'please-select-crypko': 'Please select Crypko',
    duration: 'Travel Duration',
    'fast-travel': 'Fast travel',
    'fast-travel-notice': '* Please note that photo generation will still take about 20 seconds, depending on server load.',
    'first-travel-notice': '* Fast travel is set to max with no cost for the first travel.',
    'view-album-crypko': 'View in Crypko page',
    'remove-album': 'Remove from Crypko',
    'remove-msg': 'Remove travel message',
    'insufficient-album-slot': 'Insufficient album slot',
    'coin-exchange-album-msg': 'Consume {0} star coins to exchage 1 album slot?',
    'start-now': 'Start Now',
    'welcome-desc': 'Create character illustrations',
    'gen-msg': 'Character Message',
    'gen-msg-desc': 'Even with the free plan, messages can be generated with a certain probability. However, by upgrading to a paid plan, you can freely use the message generation feature.',
    'paid-world': 'Upgrade to a paid plan to unlock the travel world.',
  },
  survey: {
    'submit-error': 'Failed. You may have already submitted the survey.',
    'help-us-improve': 'Help us improve!',
    'complete-msg': '500 star coins have been added to your account! You can exchange them for CP or slots in the store.',
  },
}
